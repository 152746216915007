import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'store';
// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import Logo from 'ui-component/Logo';
import { activeItem } from 'store/slices/menu';
import { DEFAULT_PATH } from 'config';

// ===========================|| MAIN LOGO ||=========================== //

const LogoSection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigate = () => {
    dispatch(activeItem([]));
    navigate(DEFAULT_PATH);
  };

  return (
    <ButtonBase disableRipple onClick={handleNavigate}>
      <Logo />
    </ButtonBase>
  );
};

export default LogoSection;
