import React, { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import GuestGuard from 'utils/route-guard/GuestGuard';
import SoftGuard from 'utils/route-guard/SoftGuard';
import RoleGuard from 'utils/route-guard/RoleGuard';
import { SOFTWARES } from 'schemas/software';

const HomePage = Loadable(lazy(() => import('pages/home')));

// account
const UserAccount = Loadable(lazy(() => import('pages/account')));
const UserThemeSetting = Loadable(lazy(() => import('pages/theme-settings')));
const UserChartSetting = Loadable(lazy(() => import('pages/chart_setting')));

// admin dashboard
const UserManager = Loadable(lazy(() => import('pages/user-manager')));
const UserActivity = Loadable(lazy(() => import('pages/user-activity')));
const EmailActivity = Loadable(lazy(() => import('pages/email-activity')));

// drilling-comletion
const WellInsight = Loadable(lazy(() => import('pages/drilling-completion/WellInsight')));
const CompletionDesign = Loadable(lazy(() => import('pages/drilling-completion/CompletionDesign')));
const DrillingDesign = Loadable(lazy(() => import('pages/drilling-completion/DrillingDesign')));
// artificial-lift
const RodPump = Loadable(lazy(() => import('pages/artificial-lift/RodPump')));

// customized-software
const WellTrajectory = Loadable(lazy(() => import('pages/customized-software/WellTrajectory')));
const AcidGasInjection = Loadable(lazy(() => import('pages/customized-software/AcidGasInjection')));
const ProducerBHP = Loadable(lazy(() => import('pages/customized-software/ProducerBHP')));

// maintenance routing
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/Error')));

// const UnauthorizeError = Loadable(lazy(() => import('pages/maintenance/UnauthorizeError')));

const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/UnderConstruction')));

// ===========================|| SOFTWARE MAPPING ||=========================== //

const SoftWareMapping = {
  // Artificial Lift
  ArtLiftSel: <MaintenanceUnderConstruction />,
  ESP: <MaintenanceUnderConstruction />,
  GasLift: <MaintenanceUnderConstruction />,
  PCP: <MaintenanceUnderConstruction />,
  PlungerLift: <MaintenanceUnderConstruction />,
  RodPump: <RodPump />,

  // Drilling & Completion
  Cementing: <MaintenanceUnderConstruction />,
  CompletionDesign: <CompletionDesign />,
  Hydraulics: <MaintenanceUnderConstruction />,
  WellControl: <MaintenanceUnderConstruction />,
  WellInsight: <WellInsight />,
  WellTrajectory: <WellTrajectory />,

  // Production
  Entraiment: <MaintenanceUnderConstruction />,
  FlowPattern: <MaintenanceUnderConstruction />,
  InterfacialFrictionFactor: <MaintenanceUnderConstruction />,
  MultiphaseFlowModel: <MaintenanceUnderConstruction />,
  WallFrictionFactor: <MaintenanceUnderConstruction />,
  Wettability: <MaintenanceUnderConstruction />,

  // Customized Software
  AcidGasInjection: <AcidGasInjection />,
  WellBHPPredictor: <ProducerBHP />,
  HeatTransfer: <MaintenanceUnderConstruction />,
  PressureDropSlickwater: <MaintenanceUnderConstruction />
};

// ===========================|| SOFTWARE ROUTING ||=========================== //

const getSoftwareRoutes = () => {
  const softwareRoutes = SOFTWARES.filter((s) => s.ui_show === 1).map((s) => ({
    path: s.url,
    element: <SoftGuard softToGuard={s.id}>{SoftWareMapping[s.id]}</SoftGuard>
  }));
  return softwareRoutes;
};

// ===========================|| MAIN ROUTING ||=========================== //

const MainRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: '/',
      element: (
        <GuestGuard>
          <HomePage />
        </GuestGuard>
      )
    },
    {
      path: '/user/account',
      element: (
        <RoleGuard roleToGuard="user">
          <UserAccount />
        </RoleGuard>
      )
    },
    {
      path: '/user/theme-settings',
      element: (
        <RoleGuard roleToGuard="user">
          <UserThemeSetting />
        </RoleGuard>
      )
    },
    {
      path: '/user/chart-settings',
      element: (
        <RoleGuard roleToGuard="user">
          <UserChartSetting />
        </RoleGuard>
      )
    },
    {
      path: '/admin/user-manager',
      element: (
        <RoleGuard roleToGuard="admin">
          <UserManager />
        </RoleGuard>
      )
    },
    {
      path: '/admin/user-activity',
      element: (
        <RoleGuard roleToGuard="admin">
          <UserActivity />
        </RoleGuard>
      )
    },
    {
      path: '/admin/email-activity',
      element: (
        <RoleGuard roleToGuard="admin">
          <EmailActivity />
        </RoleGuard>
      )
    },
    {
      path: '/drilling-completion/drilling-design/v2',
      element: (
        <RoleGuard roleToGuard="user">
          <DrillingDesign />
        </RoleGuard>
      )
    },
    ...getSoftwareRoutes(),
    {
      path: '*',
      element: <MaintenanceError />
    }
  ]
};
export default MainRoutes;
