import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';

const ConfirmationDialog = ({ open, options, onCancel, onConfirm, onClose }) => {
  const theme = useTheme();

  const {
    title,
    description,
    content,
    confirmationText,
    cancellationText,
    dialogProps,
    confirmationButtonProps,
    cancellationButtonProps,
    titleProps,
    contentProps,
    allowClose,
    hideCancelButton
  } = options;

  return (
    <Dialog {...dialogProps} open={open} onClose={allowClose ? onClose : null}>
      {title && <DialogTitle {...titleProps}>{title}</DialogTitle>}
      {content ? (
        <DialogContent {...contentProps}>{content}</DialogContent>
      ) : (
        description && (
          <DialogContent {...contentProps}>
            <DialogContentText>
              <Typography variant="body2" component="span">
                {description}
              </Typography>
            </DialogContentText>
          </DialogContent>
        )
      )}
      <DialogActions sx={{ pr: 2.5 }}>
        {!hideCancelButton && (
          <Button size="small" sx={{ color: theme.palette.error.dark }} {...cancellationButtonProps} onClick={onCancel}>
            {cancellationText}
          </Button>
        )}
        <Button size="small" color="primary" variant="contained" {...confirmationButtonProps} onClick={onConfirm}>
          {confirmationText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
