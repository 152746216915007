import { get, isEmpty } from 'lodash';

export function getUnitPinnedRows(cols) {
  const row = {};
  cols.forEach((col) => {
    const colId = get(col, 'col_id', null);
    if (!isEmpty(colId)) {
      row[colId] = get(col, 'col_unit', '-');
    }
  });

  return [row];
}

export const serializeTableData = (tableObj, descAsName = false) => {
  const columns = get(tableObj, 'columns', []);
  const data = get(tableObj, 'data', []);
  const schema = get(tableObj, 'schema', []);
  const pinnedTopRowData = getUnitPinnedRows(schema);

  // Create table data format
  const rowData = data.map((r) => {
    const row = {};
    r.forEach((point, idx) => {
      const colKey = columns[idx];
      row[colKey] = point;
    });
    return row;
  });
  const columnDefs = schema.map((colObj) => ({
    field: get(colObj, 'col_id', null),
    headerName: descAsName ? get(colObj, 'col_desc', null) : get(colObj, 'col_name', null),
    tooltipField: get(colObj, 'col_id', null),
    headerTooltip: get(colObj, 'col_desc', null),
    hide: !get(colObj, 'ui_show', null),
    minWidth: 80
  }));

  return {
    columnDefs,
    rowData,
    pinnedTopRowData,
    schema
  };
};

export const serializeTableRowData = (tableObj) => {
  const columns = get(tableObj, 'columns', []);
  const data = get(tableObj, 'data', []);
  // Create table data format
  const rowData = data.map((r) => {
    const row = {};
    r.forEach((point, idx) => {
      const colKey = columns[idx];
      row[colKey] = point;
    });
    return row;
  });
  return rowData;
};

export default serializeTableData;
