import PropTypes from 'prop-types';
import React, { createContext, useEffect, useReducer } from 'react';

// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import { ACCOUNT_INITIALIZE, LOGIN, LOGOUT, SAVE_PROFILE } from 'store/types';
import accountReducer from 'store/accountReducer';

// project imports
import api from '../utils/auth/api';
import TokenService from 'utils/auth/token.service';
import Loader from 'ui-component/Loader';

const verifyToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  return decoded.exp > Date.now() / 1000;
};

// const setSession = ({ accessToken, refreshToken }) => {
//   if (accessToken) {
//     TokenService.setUser({ accessToken, refreshToken });
//     api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
//   } else {
//     TokenService.removeUser();
//     delete api.defaults.headers.common.Authorization;
//   }
// };

// ===========================|| CONSTANT ||=========================== //

// constant

// const NODE_ENV = 'development'; // yarn start
const NODE_ENV = 'production'; // docker-compose

const MOCK_ADMIN = {
  id: 'user0',
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  username: 'ppsc@ppscnm.com',
  createdDate: '12/12/2021',
  modifiedDate: '12/12/2021',
  password: '1234567',
  totalSessionsAllowed: 1,
  image: 'contexts/mockAccImg.svg',
  active: true,
  roles: ['user', 'admin'],
  softwares: [
    {
      id: 'AcidGasInjection',
      name: 'Acid Gas Injection',
      expiredDate: '5/30/2025',
      createdBy: 'ppsc@ppscnm.com',
      createdDate: '4/11/2022',
      modifiedDate: '4/11/2022'
    },
    {
      id: 'WellBHPPredictor',
      name: 'Bottom Hole Pressure',
      expiredDate: '5/30/2025',
      createdBy: 'ppsc@ppscnm.com',
      createdDate: '4/11/2022',
      modifiedDate: '4/11/2022'
    },
    {
      id: 'RodPump',
      name: 'Rod Pump',
      expiredDate: '5/30/2025',
      createdBy: 'ppsc@ppscnm.com',
      createdDate: '4/11/2022',
      modifiedDate: '4/11/2022'
    },
    {
      id: 'WellInsight',
      name: 'Well Insight',
      expiredDate: '5/23/2025',
      createdBy: 'ppsc@ppscnm.com',
      createdDate: '4/23/2022',
      modifiedDate: '4/23/2022'
    },
    {
      id: 'CompletionDesign',
      name: 'Completion Design',
      expiredDate: '5/31/2023',
      createdBy: 'ppsc@ppscnm.com',
      createdDate: '9/23/2022',
      modifiedDate: '9/23/2022'
    },
    {
      id: 'WellTrajectory',
      name: 'Well Survey Viewer',
      expiredDate: '5/30/2025',
      createdBy: 'ppsc@ppscnm.com',
      createdDate: '4/11/2022',
      modifiedDate: '4/11/2022'
    }
  ],
  // User Profile
  profile: {
    firstName: 'PPSC',
    lastName: 'Vuong',
    email: 'admin@ppscnm.com',
    company: 'ppsc',
    address: '',
    country: 'USA',
    phone: '918-123-4567'
  },
  // User Settings
  settings: {}
};

// ===========================|| JWT CONTEXT & PROVIDER ||=========================== //

const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const JWTContext = createContext({
  ...initialState,
  login: () => Promise.resolve(),
  logout: () => {},
  updateProfile: () => {},
  checkToken: () => {},
  resetPassword: () => {}
});

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);

  const login = async (username, password) => {
    let user = { ...MOCK_ADMIN };
    if (process.env.NODE_ENV === NODE_ENV) {
      // Convention inside docker network
      const response = await api.post(`/auth/signin`, { username: username.toLowerCase(), password });
      user = response.data;
    }
    const { accessToken, refreshToken } = user;
    TokenService.setUser({ accessToken, refreshToken });
    dispatch({
      type: LOGIN,
      payload: { user }
    });
  };

  const logout = () => {
    TokenService.removeUser();
    TokenService.removeApp();
    dispatch({ type: LOGOUT });
  };

  const updateProfile = (profile) => {
    dispatch({
      type: SAVE_PROFILE,
      payload: { profile }
    });
  };

  const resetPassword = (email) => {
    // TODO: implement reset password
    console.log(email);
  };

  const checkToken = async () => {
    if (process.env.NODE_ENV === NODE_ENV) {
      api.get(`/user/checkToken`).then(
        (response) => response.data,
        () => logout()
      );
    }
    return 'DEV';
  };

  useEffect(() => {
    const init = async () => {
      let isLoggedIn = false;
      let user = null;
      try {
        const accessToken = TokenService.getLocalAccessToken();
        if (accessToken && verifyToken(accessToken)) {
          const response = await api.get(`/user/getProfile`);
          user = response.data;
          isLoggedIn = true;
        }
      } catch (err) {
        console.log(err);
      }
      dispatch({
        type: ACCOUNT_INITIALIZE,
        payload: {
          isLoggedIn,
          user
        }
      });
    };

    if (process.env.NODE_ENV === NODE_ENV) {
      init();
    } else {
      dispatch({
        type: ACCOUNT_INITIALIZE,
        payload: {
          isLoggedIn: true,
          user: MOCK_ADMIN
        }
      });
    }
  }, []);

  if (!state.isInitialized) {
    return <Loader />;
  }

  return (
    <JWTContext.Provider value={{ ...state, login, logout, updateProfile, checkToken, resetPassword }}>{children}</JWTContext.Provider>
  );
};

JWTProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default JWTContext;
