// INITIAL STATE
// ==========================================================================

export const initialState = {
  activityQueries: {
    emails: '',
    status: '',
    startDate: null,
    endDate: null,
    limit: { label: '10000', id: '10000' }
  },
  activityIds: [],
  activitiesById: {}
};

export default initialState;
