import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import InvertColorsIcon from '@mui/icons-material/InvertColors';

export default {
  id: 'BrowserSettings',
  title: 'Browser Settings',
  type: 'group',
  children: [
    {
      id: 'Theme',
      title: 'Theme Settings',
      type: 'item',
      url: '/user/theme-settings',
      icon: InvertColorsIcon,
      breadcrumbs: false
    }
    // {
    //   id: 'Chart',
    //   title: 'Chart Settings',
    //   type: 'item',
    //   url: '/user/chart-settings',
    //   icon: AutoGraphIcon,
    //   breadcrumbs: false
    // }
  ]
};
