// action - state management
import * as actionTypes from './types';
import get from 'lodash/get';

export const initialState = {
  withUnsavedWork: false,
  unsavedWorkMsg: ''
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_UNSAVED_STATUS: {
      const unsavedWorkMsg = get(action, 'unsavedWorkMsg', '');
      const withUnsavedWork = get(action, 'withUnsavedWork', false);
      return {
        ...state,
        withUnsavedWork,
        unsavedWorkMsg
      };
    }
    default:
      return state;
  }
};

export default globalReducer;
