import api from 'utils/auth/api';

const ADMIN_URL = '/admin';

// ADMIN
// =================================================
export const fetchUserActivity = async (payload) => {
  // GET USER LIST FROM BACKEND
  const response = await api.post(`${ADMIN_URL}/getUserActivity`, payload);

  return response.data;
};

export const fetchUserList = async () => {
  // GET USER LIST FROM BACKEND
  const response = await api.get(`${ADMIN_URL}/getUserList?detail=true`);

  return response.data;
};

export const fixActivitiesData = async (payload) => {
  // GET USER LIST FROM BACKEND
  const response = await api.post(`${ADMIN_URL}/fixActivitiesData`, payload);

  return response.data;
};

export default fetchUserActivity;
