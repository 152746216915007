// assets
import { IconBrandChrome } from '@tabler/icons';

// constant
const icons = { IconBrandChrome };

// ===========================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||=========================== //

export default {
  id: 'admin-dashboard',
  title: 'Admin Dashboard',
  type: 'group',
  children: [
    {
      id: 'user-manager',
      title: 'User Manager',
      type: 'item',
      url: '/admin/user-manager',
      icon: icons.IconBrandChrome,
      breadcrumbs: false
    },
    {
      id: 'user-activity',
      title: 'User Activity',
      type: 'item',
      url: '/admin/user-activity',
      icon: icons.IconBrandChrome,
      breadcrumbs: false
    },
    {
      id: 'email-activity',
      title: 'Email Activity',
      type: 'item',
      url: '/admin/email-activity',
      icon: icons.IconBrandChrome,
      breadcrumbs: false
    },
    {
      id: 'drilling-design-v2',
      title: 'Drilling Design v2',
      type: 'item',
      url: '/drilling-completion/drilling-design/v2',
      icon: icons.IconBrandChrome,
      breadcrumbs: false
    }
  ]
};
