// INITIAL STATE
// ==========================================================================

export const initialState = {
  activityQueries: {
    username: '',
    softwares: [],
    userAction: '',
    actionStatus: '',
    actionType: '',
    startDate: null,
    endDate: null,
    limit: { label: '10000', id: '10000' }
  },
  activityIds: [],
  activitiesById: {}
};

export default initialState;
