export const NAMESPACE = 'user-manager';

// CASE MANAGER
// ==========================================================================

export const SET_USER_DATA = `${NAMESPACE}/SET_USER_DATA`;
export const SELECT_USER = `${NAMESPACE}/SELECT_USER`;
export const ADD_USER = `${NAMESPACE}/ADD_USER`;
export const REMOVE_USER = `${NAMESPACE}/REMOVE_USER`;
export const SAVE_USER = `${NAMESPACE}/SAVE_USER`;
export const SET_USER_CHANGED = `${NAMESPACE}/SET_USER_CHANGED`;
export const ADD_USER_LIST = `${NAMESPACE}/ADD_USER_LIST`;
export const UPDATE_USER_LIST = `${NAMESPACE}/UPDATE_USER_LIST`;
