import { uniq } from 'lodash';
import { createSelector } from 'reselect';
import { initialState } from './initialState';
import * as constants from './constants';

// CURRENT STORE SELECTORS
// ==========================

export const getStore = (store) => store[constants.NAMESPACE] || initialState;

export const activityQueriesSelector = (store) => getStore(store).activityQueries;

export const activitiesByIdSelector = (store) => getStore(store).activitiesById;

export const activityIdsSelector = (store) => getStore(store).activityIds;

export const activitiesSelector = createSelector(activityIdsSelector, activitiesByIdSelector, (ids, byId) => ids.map((id) => byId[id]));

export const uniqUsersSelector = createSelector(activitiesSelector, (rows) => {
  const users = rows.map((d) => d.userName);
  const uniqUsers = uniq(users);
  return uniqUsers;
});

export const totalActivityByUserSelector = createSelector(uniqUsersSelector, activitiesSelector, (uniqUsers, activities) => {
  const data = [];
  uniqUsers.forEach((user) => {
    const userActions = activities.filter((d) => d.userName === user);
    const row = {
      userName: user,
      actionNum: userActions.length
    };
    data.push(row);
  });
  return data;
});

export const totalActivityBySoftwareSelector = createSelector(activitiesSelector, (activities) => {
  const softwares = activities.map((a) => a.softwareName);
  const uniqSoftwares = uniq(softwares);
  const data = [];
  uniqSoftwares.forEach((a) => {
    const userActions = activities.filter((d) => d.softwareName === a);
    const row = {
      softwareName: a,
      actionNum: userActions.length
    };
    data.push(row);
  });
  return data;
});

export const totalActivityByTimeSelector = createSelector(activitiesSelector, (activities) => {
  const data = [];
  const dateData = activities.map((a) => new Date(a.actionDate).toLocaleDateString('en-US'));
  const uniqDates = uniq(dateData);
  uniqDates.forEach((dateStr) => {
    const userActions = dateData.filter((d) => d === dateStr);
    const row = {
      dateStr,
      actionNum: userActions.length
    };
    data.push(row);
  });
  return data;
});
