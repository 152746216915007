export const NAMESPACE = 'artificial-lift/rod_pump';

// CASE MANAGER
// ==========================================================================

export const INIT_PROJECTS = `${NAMESPACE}/INIT_PROJECTS`;
export const SELECT_PROJECTS = `${NAMESPACE}/SELECT_PROJECTS`;
export const ADD_PROJECT = `${NAMESPACE}/ADD_PROJECT`;
export const REMOVE_PROJECT = `${NAMESPACE}/REMOVE_PROJECT`;
export const UPDATE_PROJECT = `${NAMESPACE}/UPDATE_PROJECT`;

// ROD_CAT_TABLE
// =========================================================================
export const CREATE_ROD_TABLE = `${NAMESPACE}/CREATE_ROD_TABLE`;

// PROFIT INPUT
// ==========================================================================
export const UPDATE_PROFIT_CALC_INPUT = `${NAMESPACE}/UPDATE_PROFIT_INPUT`;

// SENSITIVITY MANAGER
// ==========================================================================
export const INIT_SENSITIVITIES = `${NAMESPACE}/INIT_SENSITIVITIES`;
export const CLEAR_SENSITIVITIES = `${NAMESPACE}/CLEAR_SENSITIVITIES`;
export const SELECT_SENSITIVITIES = `${NAMESPACE}/SELECT_SENSITIVITIES`;
export const ADD_SENSITIVITY = `${NAMESPACE}/ADD_SENSITIVITY `;
export const REMOVE_SENSITIVITY = `${NAMESPACE}/REMOVE_SENSITIVITY`;
export const UPDATE_SENSITIVITY = `${NAMESPACE}/UPDATE_SENSITIVITY`;

// SCENARIOS
// ==========================================================================

export const SELECT_SCENARIOS = `${NAMESPACE}/SELECT_SCENARIOS`;

// SETTINGS
// ==========================================================================

export const UPDATE_SETTINGS = `${NAMESPACE}/UPDATE_SETTINGS`;

// CONTROLLER SELECTION
// ==========================================================================

export const SELECT_CURRENT_LAYOUT = `${NAMESPACE}/SELECT_CURRENT_LAYOUT`;
