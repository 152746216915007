// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

export const INIT_ACCOUNT = {
  username: '',
  email: '',
  password: '',
  company: '',
  country: '',
  phone: '',
  roles: ['user'],
  softwares: []
};
