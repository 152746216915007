import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

// material-ui
import { TableCell, TableRow } from '@mui/material';

const FontFamily = ({ fontFamily, onChangeFontFamily, uniqueKey }) => {
  const theme = useTheme();
  const fontFamilies = [
    'sans-serif',
    'Arial',
    'Verdana',
    'Tahoma',
    'Trebuchet MS',
    'Times New Roman',
    'Georgia',
    'Garamond',
    'Courier New',
    'Brush Script MT'
  ];

  return (
    <TableRow key={uniqueKey}>
      <TableCell style={{ width: '30%' }} variant="head">
        Font Family
      </TableCell>
      <TableCell style={{ width: '70%' }}>
        <Autocomplete
          size="small"
          options={fontFamilies}
          value={fontFamily}
          onChange={(e, newVal) => onChangeFontFamily(newVal)}
          renderInput={(params) => <TextField {...params} />}
        />
      </TableCell>
    </TableRow>
  );
};

export default FontFamily;
