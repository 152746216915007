import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import { initialState } from './initialState';
import { get, min, max, map, isEmpty } from 'lodash';
import * as d3 from 'd3';
import * as constants from './constants';

// CURRENT STORE SELECTORS
// ==========================

export const getStore = (store) => store[constants.NAMESPACE] || initialState;

export const casesByIdSelector = (store) => getStore(store).casesById;

export const caseIdsSelector = (store) => getStore(store).caseIds;

export const selectedCaseIdsSelector = (store) => getStore(store).selectedCaseIds;

export const settingsSelector = (store) => getStore(store).settings;

export const casesSelector = createSelector(caseIdsSelector, casesByIdSelector, (ids, byId) =>
  ids.map((id) => ({
    id: get(byId, `${id}.id`, null),
    name: get(byId, `${id}.name`, null),
    createdDate: get(byId, `${id}.createdDate`, null),
    modifiedDate: get(byId, `${id}.modifiedDate`, null)
  }))
);

// SETTING SELECTORS
// ==========================

export const settingUnitSelector = createSelector(settingsSelector, (settings) => get(settings, 'unit', 'Field'));

// CURRENT CASE SELECTORS
// ==========================

export const currentCaseIdSelector = createSelector(selectedCaseIdsSelector, (selectedCaseIds) => {
  if (selectedCaseIds.length > 0) {
    return selectedCaseIds[0];
  }
  return null;
});

// Note: this will limit how many case data will be rendered
export const renderCaseIdsSelector = createSelector(selectedCaseIdsSelector, (selectedCaseIds) => selectedCaseIds.slice(0, 10));

export const currentCaseSelector = createSelector(casesByIdSelector, currentCaseIdSelector, (byId, id) => byId[id] || undefined);

export const descriptionSelector = createSelector(currentCaseSelector, (currentCase) => get(currentCase, 'description', {}));

export const wellboreGeometrySelector = createSelector(currentCaseSelector, (currentCase) => get(currentCase, 'wellboreGeometry', {}));

export const currentMethodSelector = createSelector(wellboreGeometrySelector, (obj) => get(obj, 'currentMethod', undefined));

export const rowDataSelector = createSelector(wellboreGeometrySelector, (obj) => {
  const rowIds = get(obj, 'rowIds', []);
  const rowsById = get(obj, 'rowsById', {});
  const rowData = rowIds.map((id) => rowsById[id]);
  return rowData;
});

export const rowByIdSelector = createSelector(wellboreGeometrySelector, (obj) => obj.rowsById);

export const rowIdsSelector = createSelector(wellboreGeometrySelector, (obj) => obj.rowIds);

export const colorAttrSelector = createSelector(wellboreGeometrySelector, (obj) => obj.colorAttr);

export const colorStopsSelector = createSelector(wellboreGeometrySelector, (obj) => obj.colorStops);

export const colorAttrMinSelector = createSelector(colorAttrSelector, rowDataSelector, (colorAttr, rowData) => {
  const attrMin = min(rowData.map((d) => d[colorAttr]));
  return attrMin;
});

export const colorAttrMaxSelector = createSelector(colorAttrSelector, rowDataSelector, (colorAttr, rowData) => {
  const attrMax = max(rowData.map((d) => d[colorAttr]));
  return attrMax;
});

// COMPARE CASES SELECTOR
// ==========================

export const dataByCaseIdSelector = createCachedSelector(
  casesByIdSelector,
  (state, caseId) => caseId,
  (casesById, caseId) => {
    // Survey Data
    const obj = get(casesById, `${caseId}.wellboreGeometry`, {});
    const rowIds = get(obj, 'rowIds', []);
    const rowsById = get(obj, 'rowsById', {});

    // Table Data
    const rowData = rowIds.map((rowId) => rowsById[rowId]);

    // Chart Data
    const colorAttr = get(obj, 'colorAttr', 'x');
    const colorAttrData = rowData.map((d) => d[colorAttr]);
    const x = rowData.map((d) => d.x);
    const y = rowData.map((d) => d.y);
    const z = rowData.map((d) => d.z);
    const name = get(casesById, `${caseId}.name`, null);
    const minColorAttr = min(colorAttrData);
    const maxColorAttr = max(colorAttrData);

    const colorStops = get(obj, 'colorStops', null);
    const colorData = [];

    if (!isEmpty(colorStops)) {
      const offsets = map(colorStops, 'offset');
      const colors = map(colorStops, 'color');
      const colorScale = d3.scale.linear().domain(offsets).range(colors).interpolate(d3.interpolateRgb);
      colorAttrData.forEach((value) => {
        let color = '#0000ff';
        if (maxColorAttr > minColorAttr && colorScale) {
          const offset = (value - minColorAttr) / (maxColorAttr - minColorAttr);
          color = colorScale(offset);
        }
        colorData.push(color);
      });
    }

    return {
      rowData,
      x,
      y,
      z,
      maxColorAttr,
      minColorAttr,
      name,
      colorData
    };
  }
)(
  (state, caseId) => caseId // Cache selectors by caseId
);
