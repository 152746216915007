import api from '../../api';

const BASE_URL = '/completion-design/v1/project';

// PROJECT CRUD
// =================================================

export const fetchProjectList = async (owner) => {
  // GET USER LIST FROM BACKEND
  const response = await api.get(`${BASE_URL}?owner=${owner}`);
  return response.data;
};

export const readProject = async (projectId) => {
  // GET USER LIST FROM BACKEND
  const response = await api.get(`${BASE_URL}/${projectId}`);
  return response.data;
};

export const updateProject = async (projectId, project) => {
  // GET USER LIST FROM BACKEND
  const response = await api.put(`${BASE_URL}/${projectId}`, project);
  return response.data;
};

export const addProject = async (project) => {
  // CALL BACKEND TO ADD USER username
  const response = await api.post(`${BASE_URL}`, project);
  return response.data;
};

export const duplicateProject = async (project) => {
  // CALL BACKEND TO ADD USER username
  const response = await api.post(`${BASE_URL}`, project);
  return response.data;
};

export const removeProject = async (projectId) => {
  // CALL BACKEND TO REMOVE USER userId
  const response = await api.delete(`${BASE_URL}/${projectId}`);
  return response.data;
};

export const permanentDeleteProjects = async () => {
  // CALL BACKEND TO REMOVE USER userId
  const response = await api.delete(`${BASE_URL}`);
  return response.data;
};

// PROJECT CONTROLLER
// =================================================

export const updateCasingDesign = async (projectId) => {
  // CALL BACKEND TO REMOVE USER userId
  const response = await api.put(`${BASE_URL}/${projectId}/casing-design`);
  return response.data;
};

export const deleteCasingDesign = async (projectId) => {
  // CALL BACKEND TO REMOVE USER userId
  const response = await api.delete(`${BASE_URL}/${projectId}/casing-design`);
  return response.data;
};

// PROJECT CRUD
// =================================================

export const fetchSensitivityList = async (projectId) => {
  // GET USER LIST FROM BACKEND
  const response = await api.get(`${BASE_URL}/${projectId}/sensitivity`);
  return response.data;
};

export const readSensitivity = async (sensitivityId) => {
  // GET USER LIST FROM BACKEND
  const response = await api.get(`${BASE_URL}/sensitivity/${sensitivityId}`);
  return response.data;
};

export const updateSensitivity = async (sensitivityId, sensitivity) => {
  // GET USER LIST FROM BACKEND
  const response = await api.put(`${BASE_URL}/sensitivity/${sensitivityId}`, sensitivity);
  return response.data;
};

export const addSensitivity = async (sensitivity) => {
  // CALL BACKEND TO ADD USER username
  const response = await api.post(`${BASE_URL}/sensitivity`, sensitivity);
  return response.data;
};

export const duplicateSensitivity = async (sensitivity) => {
  // CALL BACKEND TO ADD USER username
  const response = await api.post(`${BASE_URL}/sensitivity`, sensitivity);
  return response.data;
};

export const removeSensitivity = async (sensitivityId) => {
  // CALL BACKEND TO REMOVE USER userId
  const response = await api.delete(`${BASE_URL}/sensitivity/${sensitivityId}`);
  return response.data;
};

export const runSensitivity = async (sensitivityId) => {
  // CALL BACKEND TO REMOVE USER userId
  const response = await api.post(`${BASE_URL}/sensitivity/${sensitivityId}/run`);
  return response.data;
};

export const readStatusSensitivity = async (sensitivityId) => {
  // GET USER LIST FROM BACKEND
  const response = await api.get(`${BASE_URL}/sensitivity/${sensitivityId}/status`);
  return response.data;
};

// ACTIVITY CRUD
// =================================================

export const readManyActivity = async () => {
  // CALL BACKEND TO REMOVE USER userId
  const response = await api.get(`/completion-design/activity`);
  return response.data;
};
