const getServiceUrl = (service) => {
  if (process.env.NODE_ENV === 'production') {
    // For local testing inside docker network
    // return `http://localhost/api/${service}/`;
    // For AWS Deployment
    // return `https://lb-test.ppscnm.com/api/${service}/`;
    return `https://software.ppscnm.com/api/${service}/`;
  }

  // For local testing outside docker network
  if (service === 'auth') {
    return `http://localhost:5001/`;
  }
  if (service === 'emailing') {
    return `http://localhost:5002/`;
  }
  return `http://localhost:5000/`;
};

export default getServiceUrl;
