import { createSelector } from 'reselect';
import { initialState } from './initialState';
import { get } from 'lodash';

import * as constants from './constants';

// CURRENT STORE SELECTORS
// ==========================

export const getStore = (store) => store[constants.NAMESPACE] || initialState;

export const usersByIdSelector = (store) => getStore(store).usersById;

export const userIdsSelector = (store) => getStore(store).userIds;

export const currentUserIdSelector = (store) => getStore(store).currentUserId;

export const currentUserSelector = createSelector(currentUserIdSelector, usersByIdSelector, (currId, byIds) => {
  const currentUser = byIds[currId];
  return currentUser;
});

export const usersSelector = createSelector(userIdsSelector, usersByIdSelector, (ids, byId) =>
  ids.map((id) => ({
    id: get(byId, `${id}.id`, null),
    username: get(byId, `${id}.username`, null),
    // createdDate: get(byId, `${id}.createdDate`, null),
    // modifiedDate: get(byId, `${id}.modifiedDate`, null),
    roles: get(byId, `${id}.roles`, null),
    active: get(byId, `${id}.active`, null)
  }))
);
