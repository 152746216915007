import { initialState, getDefaultUser } from './initialState';
import * as constants from './constants';
import produce from 'immer';

// ===========================|| ACCOUNT REDUCER ||=========================== //

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_USER_DATA:
      return produce(state, (draft) => {
        const { rows } = action.payload;
        const userIds = rows.map((item) => item.id);
        const usersById = {};
        rows.forEach((item) => {
          usersById[item.id] = item;
        });
        draft.userIds = userIds;
        draft.usersById = usersById;
        draft.currentUserId = userIds.at(0);
      });

    case constants.SELECT_USER:
      return produce(state, (draft) => {
        const { id } = action.payload;
        draft.currentUserId = id;
      });

    case constants.ADD_USER:
      return produce(state, (draft) => {
        const { username, userId } = action.payload;
        const newUser = getDefaultUser({ username, userId });
        draft.usersById[userId] = newUser;
        draft.userIds.push(userId);
        draft.currentUserId = userId;
      });
    case constants.REMOVE_USER:
      return produce(state, (draft) => {
        const { userId } = action.payload;
        // Delete in obj
        delete draft.usersById[userId];
        // Delete in order array
        const idx = draft.userIds.findIndex((id) => id === userId);
        if (idx !== -1) draft.userIds.splice(idx, 1);
        // Select the first case
        draft.currentUserId = draft.userIds.at(0);
      });

    case constants.SET_USER_CHANGED:
      return produce(state, (draft) => {
        const { userId, changed } = action.payload;
        draft.usersById[userId].changed = changed;
      });

    case constants.SAVE_USER:
      return produce(state, (draft) => {
        const { userId, user } = action.payload;
        draft.usersById[userId] = user;
      });

    case constants.ADD_USER_LIST:
      return produce(state, (draft) => {
        const { userList } = action.payload;
        userList.forEach((user) => {
          const { id } = user;
          draft.usersById[id] = user;
          draft.userIds.push(id);
        });
      });

    case constants.UPDATE_USER_LIST:
      return produce(state, (draft) => {
        const { userList } = action.payload;
        userList.forEach((user) => {
          const { id } = user;
          draft.usersById[id] = user;
        });
      });
    default:
      return state;
  }
};

export default reducer;
