export const NAMESPACE = 'well-completion/drilling-design';

// PROJECT MANAGER
// ==========================================================================

export const INIT_PROJECTS = `${NAMESPACE}/INIT_PROJECTS`;
export const SELECT_PROJECTS = `${NAMESPACE}/SELECT_PROJECTS`;
export const ADD_PROJECT = `${NAMESPACE}/ADD_PROJECT`;
export const REMOVE_PROJECT = `${NAMESPACE}/REMOVE_PROJECT`;
export const UPDATE_PROJECT = `${NAMESPACE}/UPDATE_PROJECT`;

// PROJECT GENERAL INPUT
// ==========================================================================
export const UPDATE_PROJECT_INPUT_GENERAL = `${NAMESPACE}/UPDATE_PROJECT_INPUT_GENERAL`;
export const UPDATE_PROJECT_INPUT_PRESSURE = `${NAMESPACE}/UPDATE_PROJECT_INPUT_PRESSURE`;
export const UPDATE_PROJECT_INPUT_DRILLING_PROGRAM = `${NAMESPACE}/UPDATE_PROJECT_INPUT_DRILLING_PROGRAM`;

export const UPDATE_PROJECT_OUTPUT_CASING_DESIGN = `${NAMESPACE}/UPDATE_PROJECT_OUTPUT_CASING_DESIGN`;
export const UPDATE_PROJECT_OUTPUT_DRAG_TORQUE = `${NAMESPACE}/UPDATE_PROJECT_OUTPUT_DRAG_TORQUE`;
export const UPDATE_PROJECT_OUTPUT_HYDRAULIC = `${NAMESPACE}/UPDATE_PROJECT_OUTPUT_HYDRAULIC`;
// CASING SELECTION
// ==========================================================================

export const SELECT_CASINGS = `${NAMESPACE}/SELECT_CASINGS`;

// SENSITIVITY MANAGER
// ==========================================================================

export const INIT_SENSITIVITIES = `${NAMESPACE}/INIT_SENSITIVITIES`;
export const SELECT_SENSITIVITIES = `${NAMESPACE}/SELECT_SENSITIVITIES`;
export const ADD_SENSITIVITY = `${NAMESPACE}/ADD_SENSITIVITY `;
export const REMOVE_SENSITIVITY = `${NAMESPACE}/REMOVE_SENSITIVITY`;
export const UPDATE_SENSITIVITY = `${NAMESPACE}/UPDATE_SENSITIVITY`;
export const UPDATE_CASING_DESIGN_SENSITIVITY = `${NAMESPACE}/UPDATE_CASING_DESIGN_SENSITIVITY`;
export const UPDATE_HYDRAULIC_DESIGN_SENSITIVITY = `${NAMESPACE}/UPDATE_HYDRAULIC_DESIGN_SENSITIVITY`;
export const UPDATE_DRAG_TORQUE_SENSITIVITY = `${NAMESPACE}/UPDATE_DRAG_TORQUE_SENSITIVITY`;

// CASING SCENARIOS
// ==========================================================================

export const SELECT_HD_SCENARIOS = `${NAMESPACE}/SELECT_HD_SCENARIOS`;
export const SELECT_CD_SCENARIOS = `${NAMESPACE}/SELECT_CD_SCENARIOS`;
export const SELECT_DT_SCENARIOS = `${NAMESPACE}/SELECT_DT_SCENARIOS`;

// SETTINGS
// ==========================================================================

export const UPDATE_SETTINGS = `${NAMESPACE}/UPDATE_SETTINGS`;

// CONTROLLER SELECTION
// ==========================================================================

export const SELECT_CURRENT_LAYOUT = `${NAMESPACE}/SELECT_CURRENT_LAYOUT`;
