import PropTypes from 'prop-types';
import { createContext } from 'react';

// project import
import useLocalStorage from 'hooks/useLocalStorage';

const defaultConfig = {
  // ticks
  xTicksFontFamily: 'sans-serif',
  xTicksFontSize: 12,
  xTicksFontStyle: 'normal',

  yTicksFontFamily: 'sans-serif',
  yTicksFontSize: 12,
  yTicksFontStyle: 'normal',

  // labels
  xLabelFontFamily: 'sans-serif',
  xLabelFontSize: 14,
  xLabelFontStyle: 'bold',

  yLabelFontFamily: 'sans-serif',
  yLabelFontSize: 14,
  yLabelFontStyle: 'bold',

  // title
  titleFontFamily: 'sans-serif',
  titleFontSize: 15,
  titleFontStyle: 'bold',

  // legend
  legendFontFamily: 'sans-serif',
  legendFontSize: 11,
  legendFontStyle: 'normal'
};
// initial state
const initialState = {
  ...defaultConfig,
  // ticks
  onChangeXTicksFontFamily: () => {},
  onChangeXTicksFontSize: () => {},
  onChangeXTicksFontStyle: () => {},

  onChangeYTicksFontFamily: () => {},
  onChangeYTicksFontSize: () => {},
  onChangeYTicksFontStyle: () => {},

  // labels
  onChangeXLabelFontFamily: () => {},
  onChangeXLabelFontSize: () => {},
  onChangeXLabelFontStyle: () => {},

  onChangeYLabelFontFamily: () => {},
  onChangeYLabelFontSize: () => {},
  onChangeYLabelFontStyle: () => {},

  // title
  onChangeTitleFontFamily: () => {},
  onChangeTitleFontSize: () => {},
  onChangeTitleFontStyle: () => {},

  // legend
  onChangeLegendFontFamily: () => {},
  onChangeLegendFontSize: () => {},
  onChangeLegendFontStyle: () => {}
};

// ==============================|| CONFIG CONTEXT & PROVIDER ||============================== //

const ChartConfigContext = createContext(initialState);

function ChartConfigProvider({ children }) {
  const [chartConfig, setChartConfig] = useLocalStorage('ppsc-chart-settings', defaultConfig);

  // ticks
  const onChangeXTicksFontFamily = (xTicksFontFamily) => {
    setChartConfig({
      ...chartConfig,
      xTicksFontFamily
    });
  };

  const onChangeXTicksFontSize = (xTicksFontSize) => {
    setChartConfig({
      ...chartConfig,
      xTicksFontSize
    });
  };

  const onChangeXTicksFontStyle = (xTicksFontStyle) => {
    setChartConfig({
      ...chartConfig,
      xTicksFontStyle
    });
  };

  const onChangeYTicksFontFamily = (yTicksFontFamily) => {
    setChartConfig({
      ...chartConfig,
      yTicksFontFamily
    });
  };

  const onChangeYTicksFontSize = (yTicksFontSize) => {
    setChartConfig({
      ...chartConfig,
      yTicksFontSize
    });
  };

  const onChangeYTicksFontStyle = (yTicksFontStyle) => {
    setChartConfig({
      ...chartConfig,
      yTicksFontStyle
    });
  };

  // labels
  const onChangeXLabelFontFamily = (xLabelFontFamily) => {
    setChartConfig({
      ...chartConfig,
      xLabelFontFamily
    });
  };

  const onChangeXLabelFontSize = (xLabelFontSize) => {
    setChartConfig({
      ...chartConfig,
      xLabelFontSize
    });
  };

  const onChangeXLabelFontStyle = (xLabelFontStyle) => {
    setChartConfig({
      ...chartConfig,
      xLabelFontStyle
    });
  };

  const onChangeYLabelFontFamily = (yLabelFontFamily) => {
    setChartConfig({
      ...chartConfig,
      yLabelFontFamily
    });
  };

  const onChangeYLabelFontSize = (yLabelFontSize) => {
    setChartConfig({
      ...chartConfig,
      yLabelFontSize
    });
  };

  const onChangeYLabelFontStyle = (yLabelFontStyle) => {
    setChartConfig({
      ...chartConfig,
      yLabelFontStyle
    });
  };

  // title
  const onChangeTitleFontFamily = (titleFontFamily) => {
    setChartConfig({
      ...chartConfig,
      titleFontFamily
    });
  };

  const onChangeTitleFontSize = (titleFontSize) => {
    setChartConfig({
      ...chartConfig,
      titleFontSize
    });
  };

  const onChangeTitleFontStyle = (titleFontStyle) => {
    setChartConfig({
      ...chartConfig,
      titleFontStyle
    });
  };

  // Legend
  const onChangeLegendFontFamily = (legendFontFamily) => {
    setChartConfig({
      ...chartConfig,
      legendFontFamily
    });
  };

  const onChangeLegendFontSize = (legendFontSize) => {
    setChartConfig({
      ...chartConfig,
      legendFontSize
    });
  };

  const onChangeLegendFontStyle = (legendFontStyle) => {
    setChartConfig({
      ...chartConfig,
      legendFontStyle
    });
  };

  return (
    <ChartConfigContext.Provider
      value={{
        ...chartConfig,
        // ticks
        onChangeXTicksFontFamily,
        onChangeXTicksFontSize,
        onChangeXTicksFontStyle,

        onChangeYTicksFontFamily,
        onChangeYTicksFontSize,
        onChangeYTicksFontStyle,

        // labels
        onChangeXLabelFontFamily,
        onChangeXLabelFontSize,
        onChangeXLabelFontStyle,

        onChangeYLabelFontFamily,
        onChangeYLabelFontSize,
        onChangeYLabelFontStyle,

        // title
        onChangeTitleFontFamily,
        onChangeTitleFontSize,
        onChangeTitleFontStyle,

        // legend
        onChangeLegendFontFamily,
        onChangeLegendFontSize,
        onChangeLegendFontStyle
      }}
    >
      {children}
    </ChartConfigContext.Provider>
  );
}

ChartConfigProvider.propTypes = {
  children: PropTypes.node
};

export { ChartConfigProvider, ChartConfigContext };
