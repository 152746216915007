import React from 'react';

// routing
import Routes from 'routes';

// defaultTheme
import ThemeCustomization from 'themes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import Snackbar from 'ui-component/extended/Snackbar';

// auth provider
import { JWTProvider } from 'contexts/JWTContext';
import { ConfirmProvider } from 'ui-component/extended/ConfirmDialog';

// ===========================|| APP ||=========================== //

const App = () => (
  <ThemeCustomization>
    <ConfirmProvider>
      <Locales>
        <NavigationScroll>
          <JWTProvider>
            <Routes />
            <Snackbar />
          </JWTProvider>
        </NavigationScroll>
      </Locales>
    </ConfirmProvider>
  </ThemeCustomization>
);

export default App;
