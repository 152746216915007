import { initialState } from './initialState';
import * as constants from './constants';
import produce from 'immer';

// ===========================|| ACCOUNT REDUCER ||=========================== //

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SET_EMAIL_ACTIVITIES:
      return produce(state, (draft) => {
        const { rowData } = action.payload;
        // eslint-disable-next-line no-underscore-dangle
        const ids = rowData.map((item) => item.id);
        const byId = {};
        console.log('rowData:', rowData);
        rowData.forEach((item) => {
          // eslint-disable-next-line no-underscore-dangle
          const softwares = item.softwares.map((i) => `${i.software}-${i.daysToExp} days`);
          item.softwareStr = softwares.join(', ');
          byId[item.id] = item;
        });
        draft.activityIds = ids;
        draft.activitiesById = byId;
      });

    case constants.UPDATE_ACTIVITY_QUERIES:
      return produce(state, (draft) => {
        const { activityQueries } = action.payload;
        console.log('activityQueries:', activityQueries);
        draft.activityQueries = activityQueries;
      });
    default:
      return state;
  }
};

export default reducer;
