import { createSelector } from 'reselect';
import { initialState } from './initialState';
import * as constants from './constants';
import { PRESSURE_PROFILE_COLS } from '../schema/pressure_profile';
import get from 'lodash/get';
import round from 'lodash/round';
import find from 'lodash/find';

// CURRENT STORE SELECTORS
// ==========================

export const getStore = (store) => store[constants.NAMESPACE] || initialState;

export const projectsByIdSelector = (store) => getStore(store).projectsById;

export const selectedProjectIdsSelector = (store) => getStore(store).selectedProjectIds;

export const projectIdsSelector = (store) => getStore(store).projectIds;

export const projectsSelector = createSelector(projectIdsSelector, projectsByIdSelector, (ids, byId) => ids.map((id) => byId[id]));

export const currentRowIdsSelector = (store) => getStore(store).currentRowIds;

// CURRENT CASE SELECTORS
// ==========================

export const currentProjectIdSelector = createSelector(selectedProjectIdsSelector, (selectedProjectIds) => {
  if (selectedProjectIds.length > 0) {
    return selectedProjectIds[0];
  }
  return null;
});

export const currentProjectSelector = createSelector(projectsByIdSelector, currentProjectIdSelector, (byId, id) => byId[id] || undefined);

export const currentRowDataSelector = createSelector(currentProjectSelector, (obj) => get(obj, 'input_table', []));
export const resultByRowIdSelector = createSelector(currentProjectSelector, (obj) => get(obj, 'result_by_row_id', []));

export const currentRowsResultSelector = createSelector(
  currentRowIdsSelector,
  resultByRowIdSelector,
  currentRowDataSelector,
  (ids, byId, rowData) =>
    ids.map((id) => {
      const selectedRow = find(rowData, { id });
      const selectedCase = get(selectedRow, 'case', null);
      const selectedWell = get(selectedRow, 'well', null);
      return {
        id,
        case: selectedCase,
        well: selectedWell,
        data: byId[id]
      };
    })
);

export const currentResultTabletSelector = createSelector(
  currentRowIdsSelector,
  resultByRowIdSelector,
  currentRowDataSelector,
  (ids, byId, rowData) => {
    const columnSchema = PRESSURE_PROFILE_COLS;
    const columnDefs = [
      {
        field: 'well',
        headerName: 'Well',
        tooltipField: 'well',
        headerTooltip: 'Well'
      },
      {
        field: 'case',
        headerName: 'Case',
        tooltipField: 'case',
        headerTooltip: 'Case'
      }
    ];

    columnSchema.forEach((d) => {
      columnDefs.push({
        field: d.id,
        headerName: d.name,
        tooltipField: d.id,
        headerTooltip: d.name
      });
    });

    const cleanRowData = [];
    ids.forEach((rowId) => {
      const selectedRow = find(rowData, { id: rowId });
      const selectedCase = get(selectedRow, 'case', null);
      const selectedWell = get(selectedRow, 'well', null);
      const selectedResult = get(byId, `${rowId}`, []);
      selectedResult.forEach((row) => {
        const newRow = { ...row, case: selectedCase, well: selectedWell };
        columnSchema.forEach((col) => {
          const { decimal, id } = col;
          if (decimal != null) {
            newRow[id] = round(row[id], decimal);
          }
        });
        cleanRowData.push(newRow);
      });
    });
    return {
      columnDefs,
      rowData: cleanRowData
    };
  }
);
