import axios from 'axios';
import TokenService from 'utils/auth/token.service';
import getServiceUrl from 'utils/service-url';

const axiosInstance = axios.create({
  // baseURL: 'http://localhost:5000/',
  baseURL: getServiceUrl('emailing'),
  headers: {
    'Content-Type': 'application/json'
  }
});

const logout = () => {
  TokenService.removeUser();
  TokenService.removeApp();
  window.location.href = '/login';
};

axiosInstance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // for Spring Boot back-end
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (res) => res,
  async (err) => {
    // Access Token was expired
    /* eslint no-underscore-dangle: 0 */
    if (err.response.status === 403 || err.response.status === 401) {
      logout();
    }
    return Promise.reject(err);
  }
);

export default axiosInstance;
