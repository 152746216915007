// INITIAL STATE
// ==========================================================================

export const initialState = {
  currentLayout: 'Main', // 'project' || 'sensitivity'

  // Project
  projectIds: [],
  projectsById: {},
  selectedProjectIds: [],
  selectedCasingIds: [],

  // Project General Input
  inputGeneralByProjectId: {},
  inputPressureByProjectId: {},
  inputDrillingProgramByProjectId: {},
  outputCasingDesignByProjectId: {},
  outputDragTorqueByProjectId: {},
  outputHydraulicByProjectId: {},

  // Sensitivity
  selectedSensitivityIds: [],
  sensitivityIds: [],
  sensitivitiesById: {},

  hdSensitivitiesById: {},
  cdSensitivitiesById: {},
  dtSensitivitiesById: {},

  selectedHdScenarioIds: [],
  selectedCdScenarioIds: [],
  selectedDtScenarioIds: [],
  // Settings
  settings: {}
};

export default initialState;
