import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { TableCell, TableRow, Autocomplete, TextField } from '@mui/material';

const FontStyle = ({ fontStyle, onChangeFontStyle, uniqueKey }) => {
  const theme = useTheme();
  const fontStyles = ['normal', 'italic', 'bold', 'oblique'];

  return (
    <TableRow key={uniqueKey}>
      <TableCell width="15%" variant="head">
        Font Style
      </TableCell>
      <TableCell>
        <Autocomplete
          size="small"
          options={fontStyles}
          value={fontStyle}
          onChange={(e, newVal) => onChangeFontStyle(newVal)}
          renderInput={(params) => <TextField {...params} />}
        />
      </TableCell>
    </TableRow>
  );
};

export default FontStyle;
