import { createSelector } from 'reselect';
import { initialState } from './initialState';
import * as constants from './constants';
import get from 'lodash/get';

// CURRENT STORE SELECTORS
// ==========================

export const getStore = (store) => store[constants.NAMESPACE] || initialState;

export const projectsByIdSelector = (store) => getStore(store).projectsById;

export const selectedProjectIdsSelector = (store) => getStore(store).selectedProjectIds;

export const projectIdsSelector = (store) => getStore(store).projectIds;

export const projectsSelector = createSelector(projectIdsSelector, projectsByIdSelector, (ids, byId) => ids.map((id) => byId[id]));

export const currentRowIdsSelector = (store) => getStore(store).currentRowIds;

// CURRENT CASE SELECTORS
// ==========================

export const currentProjectIdSelector = createSelector(selectedProjectIdsSelector, (selectedProjectIds) => {
  if (selectedProjectIds.length > 0) {
    return selectedProjectIds[0];
  }
  return null;
});

export const currentRowIdSelector = createSelector(currentRowIdsSelector, (currentRowIds) => {
  if (currentRowIds.length > 0) {
    return currentRowIds[0];
  }
  return null;
});

export const currentProjectSelector = createSelector(projectsByIdSelector, currentProjectIdSelector, (byId, id) => byId[id] || undefined);

export const currentRowDataSelector = createSelector(currentProjectSelector, (obj) => get(obj, 'input_table', []));
export const resultByRowIdSelector = createSelector(currentProjectSelector, (obj) => get(obj, 'result_by_row_id', []));

export const currentRowsResultSelector = createSelector(currentRowIdsSelector, resultByRowIdSelector, (ids, byId) =>
  ids.map((id) => byId[id])
);

export const currentRowResultSelector = createSelector(currentRowsResultSelector, (currentRowsResult) => {
  if (currentRowsResult.length > 0) {
    return currentRowsResult[0];
  }
  return null;
});
