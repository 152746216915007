export const ALL_TABLE_COLUMNS = [
  {
    table_id: 'cd_pore_fracture',
    col_id: 'md',
    col_name: 'MD',
    col_desc: 'Measure Depth',
    col_unit: 'ft',
    ui_editable: true,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_pore_fracture',
    col_id: 'inclination',
    col_name: 'Inclination',
    col_desc: 'Inclination Angle',
    col_unit: 'deg',
    ui_editable: true,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_pore_fracture',
    col_id: 'azimuth',
    col_name: 'Azimuth',
    col_desc: 'Azimuth',
    col_unit: 'deg',
    ui_editable: true,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_pore_fracture',
    col_id: 'pp',
    col_name: 'P Pore',
    col_desc: 'Pore Pressure',
    col_unit: 'psi',
    ui_editable: true,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_pore_fracture',
    col_id: 'pf',
    col_name: 'P Fracture',
    col_desc: 'Fracture Pressure',
    col_unit: 'psi',
    ui_editable: true,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_pore_fracture',
    col_id: 'z',
    col_name: 'z TVD',
    col_desc: 'True Vertical Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_pore_fracture',
    col_id: 'x',
    col_name: 'x +N/-S',
    col_desc: 'x +N/-S',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_pore_fracture',
    col_id: 'y',
    col_name: 'y +E/-W',
    col_desc: 'y +E/-W',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_pore_fracture',
    col_id: 'dx',
    col_name: 'dx',
    col_desc: 'Delta x',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_pore_fracture',
    col_id: 'dy',
    col_name: 'dy',
    col_desc: 'Delta y',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_pore_fracture',
    col_id: 'dz',
    col_name: 'dz',
    col_desc: 'Delta z',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_pore_fracture',
    col_id: 'departure',
    col_name: 'Departure',
    col_desc: 'Horizontal Departure',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_pore_fracture',
    col_id: 'dls_2d',
    col_name: '2D DLS',
    col_desc: '2D Doglet Severity',
    col_unit: 'deg/100ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_pore_fracture',
    col_id: 'dls_3d',
    col_name: '3D DLS',
    col_desc: '2D Doglet Severity',
    col_unit: 'deg/100ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_burst_collapse',
    col_id: 'md',
    col_name: 'MD',
    col_desc: 'Measure Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_burst_collapse',
    col_id: 'z',
    col_name: 'z TVD',
    col_desc: 'True Vertical Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_burst_collapse',
    col_id: 'p_burst',
    col_name: 'P Burst',
    col_desc: 'Burst Pressure Without Safety Factor',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_burst_collapse',
    col_id: 'p_collapse',
    col_name: 'P Collapse',
    col_desc: 'Collapse Pressure Without Safety Factor',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_burst_collapse',
    col_id: 'p_burst_sf',
    col_name: 'Design P Burst',
    col_desc: 'Design Burst Pressure With Safety Factor',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_burst_collapse',
    col_id: 'p_collapse_sf',
    col_name: 'Design P Collapse',
    col_desc: 'Design Collapse Pressure With Safety Factor',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_burst_collapse',
    col_id: 'f_load',
    col_name: 'F Load',
    col_desc: 'F Load',
    col_unit: 'lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_casing_envelope',
    col_id: 'sigma_z',
    col_name: 'Sigma z',
    col_desc: 'Sigma z',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_casing_envelope',
    col_id: 'dp_burst',
    col_name: 'dp Burst',
    col_desc: 'dp Burst',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_casing_envelope',
    col_id: 'dp_collapse',
    col_name: 'dp Collapse',
    col_desc: 'dp Collapse',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_program',
    col_id: 'md',
    col_name: 'MD',
    col_desc: 'Measure Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_hydraulic_program',
    col_id: 'z',
    col_name: 'z TVD',
    col_desc: 'True Vertical Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_hydraulic_program',
    col_id: 'pp',
    col_name: 'P Pore',
    col_desc: 'Pore Pressure',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_hydraulic_program',
    col_id: 'pf',
    col_name: 'P Fracture',
    col_desc: 'Fracture Pressure',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_hydraulic_program',
    col_id: 'ED_pp',
    col_name: 'ED Pore',
    col_desc: 'Pore Equivalent Density',
    col_unit: 'ppg',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_program',
    col_id: 'ED_pf',
    col_name: 'ED Fracture',
    col_desc: 'Fracture  Equivalent Density',
    col_unit: 'ppg',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_program',
    col_id: 'q',
    col_name: 'Flow Rate',
    col_desc: 'Circulate Rate',
    col_unit: 'gpm',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_program',
    col_id: 'P_static',
    col_name: 'P Static',
    col_desc: 'Static Pressure',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_program',
    col_id: 'ED_static',
    col_name: 'Static ED',
    col_desc: 'Static Equivalent Density',
    col_unit: 'ppg',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_program',
    col_id: 'Pfric_tot',
    col_name: 'dP Friction',
    col_desc: 'Total dP Friction in Pipe and Annulus',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_program',
    col_id: 'P_drillbit',
    col_name: 'dP DrillBit',
    col_desc: 'dP DrillBit',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_program',
    col_id: 'P_pump',
    col_name: 'P Pump',
    col_desc: 'Required Mud Pump Pressure',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_program',
    col_id: 'HP_req',
    col_name: 'HP Pump',
    col_desc: 'Required Mud Pump Power',
    col_unit: 'HP',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_program',
    col_id: 'BHP',
    col_name: 'BHP',
    col_desc: 'Bottom hole Pressure',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_program',
    col_id: 'ECD',
    col_name: 'ECD',
    col_desc: 'Equivalent Circulation Density',
    col_unit: 'ppg',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_program',
    col_id: 'Ptrip_total',
    col_name: 'dP Trip',
    col_desc: 'Total dP Tripping',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_program',
    col_id: 'P_surge',
    col_name: 'P Surge',
    col_desc: 'Surge Pressure',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_program',
    col_id: 'P_swab',
    col_name: 'P Swab',
    col_desc: 'Swab Pressure',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_program',
    col_id: 'ED_surge',
    col_name: 'Surge ED',
    col_desc: 'Surge ED',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_program',
    col_id: 'ED_swab',
    col_name: 'Swab ED',
    col_desc: 'Swab ED',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_v_annular',
    col_id: 'md',
    col_name: 'MD',
    col_desc: 'Measure Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_v_annular',
    col_id: 'z',
    col_name: 'z TVD',
    col_desc: 'True Vertical Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'md',
    col_name: 'MD',
    col_desc: 'Measure Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'z',
    col_name: 'z TVD',
    col_desc: 'True Vertical Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'inclination',
    col_name: 'Inclination',
    col_desc: 'Inclination Angle',
    col_unit: 'deg',
    ui_editable: true,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'pipe_type',
    col_name: 'Pipe Type',
    col_desc: 'Pipe Type',
    col_unit: '-',
    ui_editable: true,
    ui_show: true,
    data_type: 'str',
    decimal: ''
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'F_drag',
    col_name: 'Axial Drag Force',
    col_desc: 'Axial Drag Force',
    col_unit: 'lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'F_static',
    col_name: 'Axial Force Static',
    col_desc: 'Axial Force Static',
    col_unit: 'lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'F_trip_in',
    col_name: 'Axial Force Trip In',
    col_desc: 'Axial Force Trip In',
    col_unit: 'lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'F_trip_out',
    col_name: 'Axial Force Trip Out',
    col_desc: 'Axial Force Trip Out',
    col_unit: 'lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'F_drilling',
    col_name: 'Axial Force Drilling',
    col_desc: 'Axial Force Drilling',
    col_unit: 'lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'HL_static',
    col_name: 'Hook Load Static',
    col_desc: 'Hook Load Static',
    col_unit: 'lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'HL_trip_in',
    col_name: 'Hook Load Trip In',
    col_desc: 'Hook Load Trip In',
    col_unit: 'lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'HL_trip_out',
    col_name: 'Hook Load Trip Out',
    col_desc: 'Hook Load Trip Out',
    col_unit: 'lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'Torque',
    col_name: 'Torque',
    col_desc: 'Torque',
    col_unit: 'ft-lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque',
    col_id: 'w_buckling',
    col_name: 'Wcr Buckling',
    col_desc: 'Wcr Buckling',
    col_unit: 'lb/ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_casing_catalog',
    col_id: 'OD',
    col_name: 'Outer Diameter',
    col_desc: 'Outer Diameter',
    col_unit: 'in',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_casing_catalog',
    col_id: 'ID',
    col_name: 'Inner Diameter',
    col_desc: 'Inner Diameter',
    col_unit: 'in',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_casing_catalog',
    col_id: 'wpf',
    col_name: 'Weight',
    col_desc: 'Weight',
    col_unit: 'lb/ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_casing_catalog',
    col_id: 'grade',
    col_name: 'Grade',
    col_desc: 'Grade',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: ''
  },
  {
    table_id: 'cd_casing_catalog',
    col_id: 'sigma_y',
    col_name: 'Sigma Y',
    col_desc: 'Sigma Y',
    col_unit: '1000 psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_casing_catalog',
    col_id: 'f_yield',
    col_name: 'Yield Strength',
    col_desc: 'Yield Strength',
    col_unit: '1000 lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_casing_catalog',
    col_id: 'p_collapse',
    col_name: 'Collapse Pressure',
    col_desc: 'Collapse Pressure',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_casing_catalog',
    col_id: 'p_burst',
    col_name: 'Burst Pressure',
    col_desc: 'Burst Pressure',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_casing_catalog',
    col_id: 'ID_drift',
    col_name: 'Inner Drift Diameter',
    col_desc: 'Inner Drift Diameter',
    col_unit: 'in',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_casing_catalog',
    col_id: 't',
    col_name: 'Thickness',
    col_desc: 'Thickness',
    col_unit: 'in',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_drillpipe_catalog',
    col_id: 'OD_text',
    col_name: 'Outer Diameter',
    col_desc: 'Outer Diameter',
    col_unit: 'in',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: ''
  },
  {
    table_id: 'cd_drillpipe_catalog',
    col_id: 'OD',
    col_name: 'Outer Diameter',
    col_desc: 'Outer Diameter',
    col_unit: 'in',
    ui_editable: false,
    ui_show: false,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_drillpipe_catalog',
    col_id: 'connection',
    col_name: 'Connection',
    col_desc: 'Connection',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: ''
  },
  {
    table_id: 'cd_drillpipe_catalog',
    col_id: 'grade',
    col_name: 'Grade',
    col_desc: 'Grade',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: ''
  },
  {
    table_id: 'cd_drillpipe_catalog',
    col_id: 'range',
    col_name: 'Range',
    col_desc: 'Range',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: ''
  },
  {
    table_id: 'cd_drillpipe_catalog',
    col_id: 't',
    col_name: 'Thickness',
    col_desc: 'Thickness',
    col_unit: 'in',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_drillpipe_catalog',
    col_id: 'wpf_n',
    col_name: 'Nominal Weight',
    col_desc: 'Nominal Weight',
    col_unit: 'lb/ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_drillpipe_catalog',
    col_id: 'wpf_a',
    col_name: 'Adjusted weight',
    col_desc: 'Adjusted weight',
    col_unit: 'lb/ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_drillpipe_catalog',
    col_id: 'ID',
    col_name: 'Inner Diameter',
    col_desc: 'Inner Diameter',
    col_unit: 'in',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_drillpipe_catalog',
    col_id: 'OD_joint',
    col_name: 'Tool joint OD',
    col_desc: 'Tool joint OD',
    col_unit: 'in',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: ''
  },
  {
    table_id: 'cd_drillpipe_catalog',
    col_id: 'ID_joint',
    col_name: 'Tool joint ID',
    col_desc: 'Tool joint ID',
    col_unit: 'in',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: ''
  },
  {
    table_id: 'cd_drillpipe_catalog',
    col_id: 'f_yield_joint',
    col_name: 'Tool joint Yield',
    col_desc: 'Tool joint Yield',
    col_unit: 'ft-lbs',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: ''
  },
  {
    table_id: 'cd_drillpipe_catalog',
    col_id: 'mut_min',
    col_name: 'MUT Min',
    col_desc: 'MUT Min',
    col_unit: 'ft-lbs',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: ''
  },
  {
    table_id: 'cd_drillpipe_catalog',
    col_id: 'mut_max',
    col_name: 'MUT Max',
    col_desc: 'MUT Max',
    col_unit: 'ft-lbs',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: ''
  },
  {
    table_id: 'cd_drillpipe_catalog',
    col_id: 'tensile',
    col_name: 'Tensile',
    col_desc: 'Tensile',
    col_unit: 'lbs',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: ''
  },
  {
    table_id: 'cd_hwdp_catalog',
    col_id: 'OD_text',
    col_name: 'Outer Diameter',
    col_desc: 'Outer Diameter',
    col_unit: 'in',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: ''
  },
  {
    table_id: 'cd_hwdp_catalog',
    col_id: 'OD',
    col_name: 'Outer Diameter',
    col_desc: 'Outer Diameter',
    col_unit: 'in',
    ui_editable: false,
    ui_show: false,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hwdp_catalog',
    col_id: 'connection',
    col_name: 'Connection',
    col_desc: 'Connection',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: ''
  },
  {
    table_id: 'cd_hwdp_catalog',
    col_id: 'ID_text',
    col_name: 'Inner Diameter',
    col_desc: 'Inner Diameter',
    col_unit: 'in',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: 0
  },
  {
    table_id: 'cd_hwdp_catalog',
    col_id: 'ID',
    col_name: 'Inner Diameter',
    col_desc: 'Inner Diameter',
    col_unit: 'in',
    ui_editable: false,
    ui_show: false,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hwdp_catalog',
    col_id: 'wpf_a',
    col_name: 'Adjusted weight',
    col_desc: 'Adjusted weight',
    col_unit: 'lb/ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hwdp_catalog',
    col_id: 'OD_joint',
    col_name: 'Tool joint OD',
    col_desc: 'Tool joint OD',
    col_unit: 'in',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: ''
  },
  {
    table_id: 'cd_hwdp_catalog',
    col_id: 'ID_joint',
    col_name: 'Tool joint ID',
    col_desc: 'Tool joint ID',
    col_unit: 'in',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: ''
  },
  {
    table_id: 'cd_hwdp_catalog',
    col_id: 'f_yield_joint',
    col_name: 'Tool joint Yield',
    col_desc: 'Tool joint Yield',
    col_unit: 'ft-lbs',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: ''
  },
  {
    table_id: 'cd_hwdp_catalog',
    col_id: 'mut_min',
    col_name: 'MUT Min',
    col_desc: 'MUT Min',
    col_unit: 'ft-lbs',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: ''
  },
  {
    table_id: 'cd_hwdp_catalog',
    col_id: 'mut_max',
    col_name: 'MUT Max',
    col_desc: 'MUT Max',
    col_unit: 'ft-lbs',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: ''
  },
  {
    table_id: 'cd_hwdp_catalog',
    col_id: 'tensile',
    col_name: 'Tensile',
    col_desc: 'Tensile',
    col_unit: 'lbs',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: ''
  },
  {
    table_id: 'cd_hwdp_catalog',
    col_id: 'type',
    col_name: 'Type',
    col_desc: 'Type',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: ''
  },
  {
    table_id: 'cd_hwdp_catalog',
    col_id: 'design',
    col_name: 'Design',
    col_desc: 'Design',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: ''
  },
  {
    table_id: 'cd_drillcollar_catalog',
    col_id: 'OD_text',
    col_name: 'Outer Diameter',
    col_desc: 'Outer Diameter',
    col_unit: 'in',
    ui_editable: false,
    ui_show: true,
    data_type: 'str',
    decimal: ''
  },
  {
    table_id: 'cd_drillcollar_catalog',
    col_id: 'OD',
    col_name: 'Outer Diameter',
    col_desc: 'Outer Diameter',
    col_unit: 'in',
    ui_editable: false,
    ui_show: false,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_drillcollar_catalog',
    col_id: 'ID',
    col_name: 'Inner Diameter',
    col_desc: 'Inner Diameter',
    col_unit: 'in',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_drillcollar_catalog',
    col_id: 'wpf',
    col_name: 'Weight',
    col_desc: 'Weight',
    col_unit: 'lb/ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'scenario',
    col_name: 'Scenario',
    col_desc: 'Scenario',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'md',
    col_name: 'MD',
    col_desc: 'Measure Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'z',
    col_name: 'z TVD',
    col_desc: 'True Vertical Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'pp',
    col_name: 'P Pore',
    col_desc: 'Pore Pressure',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'pf',
    col_name: 'P Fracture',
    col_desc: 'Fracture Pressure',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'ED_pp',
    col_name: 'ED Pore',
    col_desc: 'Pore Equivalent Density',
    col_unit: 'ppg',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'ED_pf',
    col_name: 'ED Fracture',
    col_desc: 'Fracture  Equivalent Density',
    col_unit: 'ppg',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'q',
    col_name: 'Flow Rate',
    col_desc: 'Circulate Rate',
    col_unit: 'gpm',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'P_static',
    col_name: 'P Static',
    col_desc: 'Static Pressure',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'ED_static',
    col_name: 'Static ED',
    col_desc: 'Static Equivalent Density',
    col_unit: 'ppg',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'Pfric_dp',
    col_name: 'dP Friction Pipe',
    col_desc: 'dP Friction in Pipe',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: false,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'Pfric_an',
    col_name: 'dP Friction Annulus',
    col_desc: 'dP Friction in Annulus',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: false,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'Pfric_tot',
    col_name: 'dP Friction',
    col_desc: 'Total dP Friction in Pipe and Annulus',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'P_drillbit',
    col_name: 'dP DrillBit',
    col_desc: 'dP DrillBit',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'P_pump',
    col_name: 'P Pump',
    col_desc: 'Required Mud Pump Pressure',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'HP_req',
    col_name: 'HP Pump',
    col_desc: 'Required Mud Pump Power',
    col_unit: 'HP',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'BHP',
    col_name: 'BHP',
    col_desc: 'Bottom hole Pressure',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'ECD',
    col_name: 'ECD',
    col_desc: 'Equivalent Circulation Density',
    col_unit: 'ppg',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'Pfric_trip',
    col_name: 'dP Friction Tripping',
    col_desc: 'dP Friction Tripping',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: false,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'P_acc_trip',
    col_name: 'dP Acceleration Tripping',
    col_desc: 'dP Acceleration Tripping',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: false,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'Ptrip_total',
    col_name: 'dP Trip',
    col_desc: 'Total dP Tripping',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'P_surge',
    col_name: 'P Surge',
    col_desc: 'Surge Pressure',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'P_swab',
    col_name: 'P Swab',
    col_desc: 'Swab Pressure',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'ED_surge',
    col_name: 'Surge ED',
    col_desc: 'Surge ED',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_hydraulic_sens',
    col_id: 'ED_swab',
    col_name: 'Swab ED',
    col_desc: 'Swab ED',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_v_annular_sens',
    col_id: 'scenario',
    col_name: 'Scenario',
    col_desc: 'Scenario',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_v_annular_sens',
    col_id: 'md',
    col_name: 'MD',
    col_desc: 'Measure Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_v_annular_sens',
    col_id: 'z',
    col_name: 'z TVD',
    col_desc: 'True Vertical Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_v_annular_sens',
    col_id: 'q',
    col_name: 'Flow Rate',
    col_desc: 'Flow Rate',
    col_unit: 'ppg',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_v_annular_sens',
    col_id: 'v_ann',
    col_name: 'Annular Velocity',
    col_desc: 'Annular Velocity',
    col_unit: 'ft/s',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_v_annular_sens',
    col_id: 'ID',
    col_name: 'Annulus ID',
    col_desc: 'Annulus ID',
    col_unit: 'in',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_v_annular_sens',
    col_id: 'OD',
    col_name: 'Annulus OD',
    col_desc: 'Annulus OD',
    col_unit: 'in',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_scenario_sens',
    col_id: 'scenario',
    col_name: 'Scenario',
    col_desc: 'Scenario',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_scenario_sens',
    col_id: 'param_1',
    col_name: 'Param 1',
    col_desc: 'Param 1',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_scenario_sens',
    col_id: 'param_2',
    col_name: 'Param 2',
    col_desc: 'Param 2',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_scenario_sens',
    col_id: 'param_3',
    col_name: 'Param 3',
    col_desc: 'Param 3',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_burst_collapse_sens',
    col_id: 'scenario',
    col_name: 'Scenario',
    col_desc: 'Scenario',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_burst_collapse_sens',
    col_id: 'md',
    col_name: 'MD',
    col_desc: 'Measure Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_burst_collapse_sens',
    col_id: 'z',
    col_name: 'z TVD',
    col_desc: 'True Vertical Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_burst_collapse_sens',
    col_id: 'p_burst',
    col_name: 'P Burst',
    col_desc: 'Burst Pressure Without Safety Factor',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_burst_collapse_sens',
    col_id: 'p_collapse',
    col_name: 'P Collapse',
    col_desc: 'Collapse Pressure Without Safety Factor',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_burst_collapse_sens',
    col_id: 'p_burst_sf',
    col_name: 'Design P Burst',
    col_desc: 'Design Burst Pressure With Safety Factor',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_burst_collapse_sens',
    col_id: 'p_collapse_sf',
    col_name: 'Design P Collapse',
    col_desc: 'Design Collapse Pressure With Safety Factor',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_burst_collapse_sens',
    col_id: 'f_load',
    col_name: 'F Load',
    col_desc: 'F Load',
    col_unit: 'lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_burst_collapse_sens',
    col_id: 'sigma_z',
    col_name: 'Sigma z',
    col_desc: 'Sigma z',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: false,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_casing_envelope_sens',
    col_id: 'scenario',
    col_name: 'Scenario',
    col_desc: 'Scenario',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_casing_envelope_sens',
    col_id: 'sigma_z',
    col_name: 'Sigma z',
    col_desc: 'Sigma z',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_casing_envelope_sens',
    col_id: 'dp_burst',
    col_name: 'dp Burst',
    col_desc: 'dp Burst',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_casing_envelope_sens',
    col_id: 'dp_collapse',
    col_name: 'dp Collapse',
    col_desc: 'dp Collapse',
    col_unit: 'psi',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_drag_torque_sens',
    col_id: 'scenario',
    col_name: 'Scenario',
    col_desc: 'Scenario',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque_sens',
    col_id: 'md',
    col_name: 'MD',
    col_desc: 'Measure Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_drag_torque_sens',
    col_id: 'z',
    col_name: 'z TVD',
    col_desc: 'True Vertical Depth',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 2
  },
  {
    table_id: 'cd_drag_torque_sens',
    col_id: 'inclination',
    col_name: 'Inclination',
    col_desc: 'Inclination Angle',
    col_unit: 'deg',
    ui_editable: true,
    ui_show: true,
    data_type: 'float',
    decimal: 3
  },
  {
    table_id: 'cd_drag_torque_sens',
    col_id: 'pipe_type',
    col_name: 'Pipe Type',
    col_desc: 'Pipe Type',
    col_unit: '-',
    ui_editable: true,
    ui_show: true,
    data_type: 'str',
    decimal: ''
  },
  {
    table_id: 'cd_drag_torque_sens',
    col_id: 'F_drag',
    col_name: 'Axial Drag Force',
    col_desc: 'Axial Drag Force',
    col_unit: 'lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque_sens',
    col_id: 'F_static',
    col_name: 'Axial Force Static',
    col_desc: 'Axial Force Static',
    col_unit: 'lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque_sens',
    col_id: 'F_trip_in',
    col_name: 'Axial Force Trip In',
    col_desc: 'Axial Force Trip In',
    col_unit: 'lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque_sens',
    col_id: 'F_trip_out',
    col_name: 'Axial Force Trip Out',
    col_desc: 'Axial Force Trip Out',
    col_unit: 'lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque_sens',
    col_id: 'F_drilling',
    col_name: 'Axial Force Drilling',
    col_desc: 'Axial Force Drilling',
    col_unit: 'lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque_sens',
    col_id: 'HL_static',
    col_name: 'Hook Load Static',
    col_desc: 'Hook Load Static',
    col_unit: 'lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque_sens',
    col_id: 'HL_trip_in',
    col_name: 'Hook Load Trip In',
    col_desc: 'Hook Load Trip In',
    col_unit: 'lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque_sens',
    col_id: 'HL_trip_out',
    col_name: 'Hook Load Trip Out',
    col_desc: 'Hook Load Trip Out',
    col_unit: 'lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque_sens',
    col_id: 'Torque',
    col_name: 'Torque',
    col_desc: 'Torque',
    col_unit: 'ft-lbf',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_drag_torque_sens',
    col_id: 'w_buckling',
    col_name: 'Wcr Buckling',
    col_desc: 'Wcr Buckling',
    col_unit: 'lb/ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_dnt_summary_sens',
    col_id: 'scenario',
    col_name: 'Scenario',
    col_desc: 'Scenario',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_dnt_summary_sens',
    col_id: 'WOB',
    col_name: 'WOB',
    col_desc: 'Weight on Bit',
    col_unit: 'lb',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_dnt_summary_sens',
    col_id: 'max_WOB',
    col_name: 'Maximum WOB',
    col_desc: 'Maximum Weight on Bit',
    col_unit: 'lb',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_dnt_summary_sens',
    col_id: 'neutral_length',
    col_name: 'Neutral Length',
    col_desc: 'Neutral Length',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_dnt_summary_sens',
    col_id: 'drillcollar_length',
    col_name: 'Drillcollar Length',
    col_desc: 'Drillcollar Length',
    col_unit: 'ft',
    ui_editable: false,
    ui_show: true,
    data_type: 'float',
    decimal: 0
  },
  {
    table_id: 'cd_dnt_summary_sens',
    col_id: 'buckling_risk',
    col_name: 'Buckling Risk',
    col_desc: 'Buckling Risk',
    col_unit: '-',
    ui_editable: false,
    ui_show: true,
    data_type: 'bool',
    decimal: 0
  }
];

export default ALL_TABLE_COLUMNS;
