import axios from 'axios';

import getServiceUrl from 'utils/service-url';
import TokenService from './token.service';

// const logout = () => {
//   TokenService.removeUser();
//   TokenService.removeApp();
//   window.location.href = '/login';
// };

const axiosInstance = axios.create({
  // baseURL: 'http://localhost:5000/auth',
  baseURL: getServiceUrl('auth'), // return 'http://localhost:5000' in dev and 'api/auth/'
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // for Spring Boot back-end
      // config.headers['x-access-token'] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;
    console.log('_error 1:', err);
    if (originalConfig.url !== '/auth/signin' && err.response) {
      // Access Token was expired
      /* eslint no-underscore-dangle: 0 */
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          // to be easy on backen, will not use refreshtoken => set accessToken 24hours
          const rs = await axiosInstance.post('/auth/refreshtoken', {
            refreshToken: TokenService.getLocalRefreshToken()
          });

          const { accessToken } = rs.data;

          TokenService.updateLocalAccessToken(accessToken);

          return axiosInstance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default axiosInstance;
