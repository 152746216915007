// DEFAULT USER
// ==========================================================================
export const getDefaultUser = ({ username, userId }) => {
  const createdDate = Date.now();
  return {
    id: userId,
    username,
    createdDate,
    modifiedDate: createdDate,
    active: true,
    roles: ['user'],
    softwares: [],
    profile: {
      firstName: '',
      lastName: '',
      email: '',
      company: '',
      address: '',
      country: '',
      phone: ''
    }
  };
};

// INITIAL STATE
// ==========================================================================

export const initialState = {
  userIds: [],
  usersById: {},
  currentUserId: null
};

export default initialState;
