import { createSelector } from 'reselect';
// import { createCachedSelector } from 're-reselect';
import { initialState } from './initialState';
import { get } from 'lodash';
// import * as d3 from 'd3';
import * as constants from './constants';
import { serializeTableData } from 'ui-component/tables/serialization';

// CURRENT STORE SELECTORS
// ==========================

export const getStore = (store) => store[constants.NAMESPACE] || initialState;

export const rodCatTableDataSelector = (store) => getStore(store).rodCatalogTableData;

export const projectIdsSelector = (store) => getStore(store).projectIds;

export const projectsByIdSelector = (store) => getStore(store).projectsById;

export const selectedProjectIdsSelector = (store) => getStore(store).selectedProjectIds;

export const sensitivityIdsSelector = (store) => getStore(store).sensitivityIds;

export const sensitivitiesByIdSelector = (store) => getStore(store).sensitivitiesById;

export const selectedSensitivityIdsSelector = (store) => getStore(store).selectedSensitivityIds;

export const settingsSelector = (store) => getStore(store).settings;

export const currentLayoutSelector = (store) => getStore(store).currentLayout;

export const currenControllerSelector = (store) => getStore(store).currentController;

export const selectedScenarioIdsSelector = (store) => getStore(store).selectedScenarioIds;

export const projectsSelector = createSelector(projectIdsSelector, projectsByIdSelector, (ids, byId) => ids.map((id) => byId[id]));

// CURRENT PROJECT SELECTORS
// ==========================

export const currentProjectIdSelector = createSelector(selectedProjectIdsSelector, (selectedProjectIds) => {
  if (selectedProjectIds.length > 0) {
    return selectedProjectIds[0];
  }
  return null;
});

export const currentProjectSelector = createSelector(projectsByIdSelector, currentProjectIdSelector, (byId, id) => byId[id] || undefined);

export const currentProjectInputSelector = createSelector(currentProjectSelector, (currentProject) => ({
  name: get(currentProject, 'name', {}),
  i_operating: get(currentProject, 'i_operating', {}),
  i_wellbore_surface: get(currentProject, 'i_wellbore_surface', {}),
  i_pump_data: get(currentProject, 'i_pump_data', {}),
  i_fluid_data: get(currentProject, 'i_fluid_data', {}),
  i_reservoir_data: get(currentProject, 'i_reservoir_data', {}),
  i_rod_data: get(currentProject, 'i_rod_data', {}),
  rod_data_non_API: get(currentProject, 'rod_data_non_API', {}),
  i_motor_data: get(currentProject, 'i_motor_data', {}),
  i_simulation_data: get(currentProject, 'i_simulation_data', {})
}));

export const projectOutputSelector = createSelector(currentProjectSelector, (currentProject) => {
  const df = get(currentProject, 'df_project_output', {});
  return serializeTableData(df);
});

export const rodloadPlungerSelector = createSelector(currentProjectSelector, (currentProject) => {
  const df = get(currentProject, 'df_rodload_plunger', {});
  return serializeTableData(df);
});

export const torqueCrankangleSelector = createSelector(currentProjectSelector, (currentProject) => {
  const df = get(currentProject, 'df_torque_crankangle', {});
  return serializeTableData(df);
});

export const rodAccelerationSelector = createSelector(currentProjectSelector, (currentProject) => {
  const df = get(currentProject, 'df_rod_acceleration', {});
  return serializeTableData(df);
});

export const nodalSeriesSelector = createSelector(currentProjectSelector, (currentProject) => {
  const df = get(currentProject, 'df_nodal_series', {});
  return serializeTableData(df);
});

export const nodalPointSelector = createSelector(currentProjectSelector, (currentProject) => {
  const df = get(currentProject, 'df_nodal_point', {});
  return serializeTableData(df);
});

export const econSeriesSelector = createSelector(currentProjectSelector, (currentProject) => {
  const df = get(currentProject, 'df_econ_series', {});
  return serializeTableData(df);
});

// CURRENT SENSITIVITY SELECTORS
// ==========================

export const currentSensitivityIdSelector = createSelector(selectedSensitivityIdsSelector, (selectedIds) => {
  if (selectedIds.length > 0) {
    return selectedIds[0];
  }
  return null;
});

export const currentSensitivitySelector = createSelector(
  sensitivitiesByIdSelector,
  currentSensitivityIdSelector,
  (byId, id) => byId[id] || undefined
);

export const sensitivitiesSelector = createSelector(sensitivityIdsSelector, sensitivitiesByIdSelector, (ids, byId) =>
  ids.map((id) => byId[id])
);

export const sensitivitiesBySelectedProjectIdSelector = createSelector(
  sensitivitiesSelector,
  selectedProjectIdsSelector,
  (sensitivities, selectedProjectIds) => sensitivities.filter((sens) => selectedProjectIds.includes(sens.project_id))
);

export const currentSensitivityScenarioSelector = createSelector(currentSensitivitySelector, (sensitivity) => {
  const df = get(sensitivity, 'df_scenario', {});
  return serializeTableData(df);
});

export const sensitivityProjectOutputSelector = createSelector(currentSensitivitySelector, (sensitivity) => {
  const dfProjectOutput = get(sensitivity, 'df_project_output', {});
  return serializeTableData(dfProjectOutput);
});

export const sensitivityRodloadPlungerSelector = createSelector(currentSensitivitySelector, (sensitivity) => {
  const dfRodloadPlunger = get(sensitivity, 'df_rodload_plunger', {});
  return serializeTableData(dfRodloadPlunger);
});

export const sensitivityTorqueCrankangleSelector = createSelector(currentSensitivitySelector, (sensitivity) => {
  const dfTorqueCrankangle = get(sensitivity, 'df_torque_crankangle', {});
  return serializeTableData(dfTorqueCrankangle);
});

export const sensitivityRodAccelerationSelector = createSelector(currentSensitivitySelector, (sensitivity) => {
  const dfRodAcceleration = get(sensitivity, 'df_rod_acceleration', {});
  return serializeTableData(dfRodAcceleration);
});

export const sensitivityNodalSeriesSelector = createSelector(currentSensitivitySelector, (sensitivity) => {
  const df = get(sensitivity, 'df_nodal_series', {});
  return serializeTableData(df);
});

export const sensitivityNodalPointSelector = createSelector(currentSensitivitySelector, (sensitivity) => {
  const df = get(sensitivity, 'df_nodal_point', {});
  return serializeTableData(df);
});

export const sensitivityEconSeriesSelector = createSelector(currentSensitivitySelector, (sensitivity) => {
  const df = get(sensitivity, 'df_econ_series', {});
  return serializeTableData(df);
});

export const sensitivityRodStressSelector = createSelector(currentSensitivitySelector, (sensitivity) => {
  const rodStress = get(sensitivity, 'df_rod_stress', {});
  return serializeTableData(rodStress);
});

export const currentSensitivitySummarySelector = createSelector(
  // combine between projectOutput and sensitivityScenario but filter out the items of which values aren't number
  currentSensitivityScenarioSelector,
  sensitivityProjectOutputSelector,
  selectedScenarioIdsSelector,
  (scenario, projectOutput, scenarioIds) => {
    const scenarioData = get(scenario, 'rowData', []);
    const scenarioColDefs = get(scenario, 'columnDefs', []);
    const scenarioPinnedData = get(scenario, 'pinnedTopRowData', []);
    const outputData = get(projectOutput, 'rowData', []);
    const selectedOutputData = outputData.filter((d) => scenarioIds.includes(d.scenario));
    const outputColDefs = get(projectOutput, 'columnDefs', []);
    const outputPinnedData = get(projectOutput, 'pinnedTopRowData', []);

    const rowById = {};
    scenarioData.forEach((r) => {
      const rowId = `id-${r.scenario}`;
      rowById[rowId] = {
        param_1: r.param_1,
        param_2: r.param_2,
        param_3: r.param_3
      };
    });
    const summaryData = selectedOutputData.map((r) => {
      const rowId = `id-${r.scenario}`;
      const row = rowById[rowId];
      return {
        ...r,
        ...row
      };
    });

    let columnDefs = [];

    if (scenarioColDefs.length > 0) {
      columnDefs = [...outputColDefs, scenarioColDefs[1], scenarioColDefs[2], scenarioColDefs[3]];
    }

    return {
      columnDefs,
      rowData: summaryData,
      pinnedTopRowData: [{ ...scenarioPinnedData[0], ...outputPinnedData[0] }]
    };
  }
);
