import { IconQrcode, IconAerialLift, IconClick, IconForklift, IconBuildingBridge, IconCircle } from '@tabler/icons';

import IconOilDrop from 'ui-component/icons/IconOilDrop';
import IconGasLift from 'ui-component/icons/GasLift';
import IconRodPump from 'ui-component/icons/RodPump';
import IconWellBHPPredictor from 'ui-component/icons/WellBHPPredictor';
import IconAcidGasInjection from 'ui-component/icons/AcidGasInjection';
import IconESP from 'ui-component/icons/ESP';
import IconPCP from 'ui-component/icons/PCP';
import IconWellTrajectory from 'ui-component/icons/WellTrajectory';
import IconWellInsight from 'ui-component/icons/WellInsight';
import IconCompletionDesign from 'ui-component/icons/CompletionDesign';

import { SOFTWARES } from 'schemas/software';

// ===========================|| ICON MAPPING ||=========================== //

export const IconMapping = {
  // Artificial Lift
  ArtLiftSel: IconClick,
  ESP: IconESP,
  GasLift: IconGasLift,
  PCP: IconPCP,
  PlungerLift: IconQrcode,
  RodPump: IconRodPump,

  // Drilling & Completion
  Cementing: IconAerialLift,
  CompletionDesign: IconCompletionDesign,
  DrillingDesign: IconCompletionDesign,
  Hydraulics: IconClick,
  WellControl: IconForklift,
  WellInsight: IconWellInsight,
  WellTrajectory: IconWellTrajectory,

  // Production
  Entraiment: IconCircle,
  FlowPattern: IconCircle,
  InterfacialFrictionFactor: IconCircle,
  MultiphaseFlowModel: IconCircle,
  WallFrictionFactor: IconCircle,
  Wettability: IconCircle,

  // Customized Software
  AcidGasInjection: IconAcidGasInjection,
  WellBHPPredictor: IconWellBHPPredictor,
  HeatTransfer: IconCircle,
  PressureDropSlickwater: IconCircle
};

// ===========================|| SOFTWARE GROUP ||=========================== //

const getGroupMenu = (groupId) => {
  const groupItems = SOFTWARES.filter((s) => s.group === groupId && s.ui_show === 1).map((s) => ({
    id: s.id,
    title: s.name,
    type: 'item',
    url: s.url,
    icon: IconMapping[s.id],
    breadcrumbs: false,
    disabled: !s.ui_clickable
  }));
  return groupItems;
};

// ===========================|| MENU ITEMS ||=========================== //

export default {
  id: 'software',
  title: 'Applications',
  type: 'group',
  children: [
    {
      id: 'Artificial Lift',
      title: 'Artificial Lift',
      icon: IconRodPump,
      type: 'collapse',
      disabled: true,
      children: getGroupMenu('Artificial Lift')
    },
    {
      id: 'drilling-completion',
      title: 'Drilling & Completion',
      type: 'collapse',
      icon: IconCompletionDesign,
      children: getGroupMenu('Drilling & Completion')
    },
    {
      id: 'production',
      title: 'Production',
      type: 'collapse',
      icon: IconOilDrop,
      children: getGroupMenu('Production')
    },
    {
      id: 'customized-software',
      title: 'Customized Software',
      icon: IconBuildingBridge,
      type: 'collapse',
      children: getGroupMenu('Customized Software')
    }
  ]
};
