export const SOFTWARES = [
  {
    id: 'ArtLiftSel',
    name: 'Artificial Lift Selection',
    description: 'Artificial Lift Selection',
    group: 'Artificial Lift',
    url: '/artificial-lift/artificial-lift-selection',
    tags: '',
    ui_clickable: 0,
    ui_show: 1
  },
  {
    id: 'ESP',
    name: 'ESP',
    description: 'ESP',
    group: 'Artificial Lift',
    url: '/artificial-lift/esp',
    tags: '',
    ui_clickable: 0,
    ui_show: 1
  },
  {
    id: 'GasLift',
    name: 'Gas Lift',
    description: 'Gas Lift',
    group: 'Artificial Lift',
    url: '/artificial-lift/gas-lift',
    tags: '',
    ui_clickable: 0,
    ui_show: 1
  },
  {
    id: 'PCP',
    name: 'PCP',
    description: 'PCP',
    group: 'Artificial Lift',
    url: '/artificial-lift/pcp',
    tags: '',
    ui_clickable: 0,
    ui_show: 1
  },
  {
    id: 'PlungerLift',
    name: 'Plunger Lift',
    description: 'Plunger Lift',
    group: 'Artificial Lift',
    url: '/artificial-lift/plunger-lift',
    tags: '',
    ui_clickable: 0,
    ui_show: 1
  },
  {
    id: 'RodPump',
    name: 'Rod Pump',
    description: 'Rod Pump',
    group: 'Artificial Lift',
    url: '/artificial-lift/rod-pump',
    tags: '',
    ui_clickable: 1,
    ui_show: 1
  },
  {
    id: 'Cementing',
    name: 'Cementing',
    description: 'Cementing',
    group: 'Drilling & Completion',
    url: '/drilling-completion/cementing',
    tags: '',
    ui_clickable: 1,
    ui_show: 1
  },
  {
    id: 'CompletionDesign',
    name: 'Drilling Design',
    description: 'Drilling Design',
    group: 'Drilling & Completion',
    url: '/drilling-completion/drilling-design/v1',
    tags: '',
    ui_clickable: 1,
    ui_show: 1
  },
  {
    id: 'DrillingDesign',
    name: 'Drilling Design v2',
    description: 'Drilling Design v2',
    group: 'Drilling & Completion',
    url: '/drilling-completion/drilling-design/v2',
    tags: '',
    ui_clickable: 1,
    ui_show: 1
  },
  {
    id: 'Hydraulics',
    name: 'Hydraulics',
    description: 'Hydraulics',
    group: 'Drilling & Completion',
    url: '/drilling-completion/hydraulics',
    tags: '',
    ui_clickable: 0,
    ui_show: 1
  },
  {
    id: 'WellControl',
    name: 'Well Control',
    description: 'Well Control',
    group: 'Drilling & Completion',
    url: '/drilling-completion/well-control',
    tags: '',
    ui_clickable: 0,
    ui_show: 1
  },
  {
    id: 'WellInsight',
    name: 'Well Insight',
    description: 'Well Insight',
    group: 'Drilling & Completion',
    url: '/drilling-completion/well-insight',
    tags: '',
    ui_clickable: 1,
    ui_show: 1
  },
  {
    id: 'AcidGasInjection',
    name: 'Acid Gas Injection',
    description: 'Acid Gas Injection',
    group: 'Customized Software',
    url: '/customized-software/acid-gas-injection',
    tags: '',
    ui_clickable: 1,
    ui_show: 1
  },
  {
    id: 'WellBHPPredictor',
    name: 'Bottomhole Pressure',
    description: 'Bottomhole Pressure',
    group: 'Customized Software',
    url: '/customized-software/well-bhp-predictor',
    tags: '',
    ui_clickable: 1,
    ui_show: 1
  },
  {
    id: 'HeatTransfer',
    name: 'Heat Transfer',
    description: 'Heat Transfer',
    group: 'Customized Software',
    url: '/customized-software/heat-transfer',
    tags: '',
    ui_clickable: 0,
    ui_show: 1
  },
  {
    id: 'PressureDropSlickwater',
    name: 'Pressure Drop Slickwater',
    description: 'Pressure Drop Slickwater',
    group: 'Customized Software',
    url: '/customized-software/pressure-drop-prediction-slickwater',
    tags: '',
    ui_clickable: 0,
    ui_show: 1
  },
  {
    id: 'WellTrajectory',
    name: 'Well Survey Viewer',
    description: 'Well Trajectory',
    group: 'Customized Software',
    url: '/customized-software/well-trajectory',
    tags: '',
    ui_clickable: 1,
    ui_show: 1
  },
  {
    id: 'Entraiment',
    name: 'Entrainment',
    description: 'Entrainment',
    group: 'Production',
    url: '/production/wettability',
    tags: '',
    ui_clickable: 0,
    ui_show: 1
  },
  {
    id: 'FlowPattern',
    name: 'Flow Pattern',
    description: 'Flow Pattern',
    group: 'Production',
    url: '/production/flow-pattern',
    tags: '',
    ui_clickable: 0,
    ui_show: 1
  },
  {
    id: 'InterfacialFrictionFactor',
    name: 'Interfacial Friction Factor',
    description: 'Interfacial Friction Factor',
    group: 'Production',
    url: '/production/interfacial-friction-factor',
    tags: '',
    ui_clickable: 0,
    ui_show: 1
  },
  {
    id: 'MultiphaseFlowModel',
    name: 'Multiphase Flow Model',
    description: 'Multiphase Flow Model',
    group: 'Production',
    url: '/production/multiphase-flow-model',
    tags: '',
    ui_clickable: 0,
    ui_show: 1
  },
  {
    id: 'WallFrictionFactor',
    name: 'Wall Friction Factor',
    description: 'Wall Friction Factor',
    group: 'Production',
    url: '/production/wall-friction-factor',
    tags: '',
    ui_clickable: 0,
    ui_show: 1
  },
  {
    id: 'Wettability',
    name: 'Wettability',
    description: 'Wettability',
    group: 'Production',
    url: '/production/entrainment',
    tags: '',
    ui_clickable: 0,
    ui_show: 1
  }
];

const SOFTWARE_BY_ID = {};

SOFTWARES.forEach((s) => {
  const { id } = s;
  SOFTWARE_BY_ID[id] = s;
});

export default SOFTWARE_BY_ID;
