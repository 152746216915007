import { useState } from 'react';

// material-ui
import { TableCell, TableRow, Box, Slider } from '@mui/material';

const FontSize = ({ fontSize, onChangeFontSize, uniqueKey, min, max }) => {
  const [size, setSize] = useState(fontSize);
  const handleSize = (event, value) => {
    setSize(value);
  };

  const handleCommit = (event, value) => {
    setSize(value);
    onChangeFontSize(value);
  };
  return (
    <TableRow key={uniqueKey}>
      <TableCell variant="head">Font Size</TableCell>
      <TableCell>
        <Slider
          style={{ maxWidth: '95%' }}
          value={size}
          aria-label="Default"
          valueLabelDisplay="on"
          onChange={handleSize}
          onChangeCommitted={handleCommit}
          min={min}
          max={max}
          width="50%"
        />
      </TableCell>
    </TableRow>
  );
};

export default FontSize;
