import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import { initialState } from './initialState';
import { get, min, max, map, isEmpty, find, orderBy } from 'lodash';
import * as d3 from 'd3';
import * as constants from './constants';
import { serializeTableData } from 'ui-component/tables/serialization';

// CURRENT STORE SELECTORS
// ==========================

export const getStore = (store) => store[constants.NAMESPACE] || initialState;

export const projectIdsSelector = (store) => getStore(store).projectIds;

export const projectsByIdSelector = (store) => getStore(store).projectsById;

export const selectedProjectIdsSelector = (store) => getStore(store).selectedProjectIds;

export const sensitivityIdsSelector = (store) => getStore(store).sensitivityIds;

export const sensitivitiesByIdSelector = (store) => getStore(store).sensitivitiesById;

export const selectedSensitivityIdsSelector = (store) => getStore(store).selectedSensitivityIds;

export const selectedCasingIdsSelector = (store) => getStore(store).selectedCasingIds;

export const settingsSelector = (store) => getStore(store).settings;

export const currentLayoutSelector = (store) => getStore(store).currentLayout;

export const selectedHScenarioIdsSelector = (store) => getStore(store).selectedHScenarioIds;
export const selectedCdScenarioIdsSelector = (store) => getStore(store).selectedCdScenarioIds;
export const selectedDntScenarioIdsSelector = (store) => getStore(store).selectedDntScenarioIds;

export const projectsSelector = createSelector(projectIdsSelector, projectsByIdSelector, (ids, byId) => ids.map((id) => byId[id]));

// CURRENT PROJECT SELECTORS
// ==========================

export const currentProjectIdSelector = createSelector(selectedProjectIdsSelector, (selectedIds) => {
  if (selectedIds.length > 0) {
    return selectedIds[0];
  }
  return null;
});

export const currentCasingIdSelector = createSelector(selectedCasingIdsSelector, (selectedIds) => {
  if (selectedIds.length > 0) {
    return selectedIds[0];
  }
  return null;
});

// Note: this will limit how many case data will be rendered
export const renderProjectIdsSelector = createSelector(selectedProjectIdsSelector, (selectedProjectIds) => selectedProjectIds.slice(0, 10));

export const currentProjectSelector = createSelector(projectsByIdSelector, currentProjectIdSelector, (byId, id) => byId[id] || undefined);

export const currentCasingSelector = createSelector(currentCasingIdSelector, currentProjectSelector, (casingId, project) => {
  /*
  Create a base case casing for sensitivity analysis
  */
  const casingProgram = get(project, 'casing_program', []);
  const casings = casingProgram.filter((m) => m.id === casingId);
  const casing = get(casings, '[0].casing', null);
  return casing;
});

export const currentBaseMudProgramSelector = createSelector(currentCasingIdSelector, currentProjectSelector, (casingId, project) => {
  /*
  Create a base case Mud Program for sensitivity analysis (mud program at shoe depth)
  */
  const mudProgram = get(project, 'mud_program', []);
  const muds = mudProgram.filter((m) => m.casing_id === casingId);
  const sortedMuds = orderBy(muds, ['drilled_depth'], ['asc']);
  return sortedMuds[sortedMuds.length - 1];
});

export const currentCasingDesignSelector = createSelector(currentProjectSelector, (currentProject) => {
  const casingDesignById = get(currentProject, 'casing_design_by_id', {});
  const casingPrograms = get(currentProject, 'casing_program', []);
  const casingIds = Object.keys(casingDesignById);

  const serializedData = {};
  casingIds.forEach((id) => {
    const casingProgram = find(casingPrograms, { id });
    const casingType = get(casingProgram, 'casing_type', 'UNKNOWN');
    const setDepth = get(casingProgram, 'set_depth', null);
    const optionLabel = `${casingType} - ${setDepth}`;
    const casingDesign = casingDesignById[id];
    const { df_bc: dfBC, df_envelope: dfEnvelope } = casingDesign;

    serializedData[id] = {
      id,
      optionLabel,
      burstCollapse: serializeTableData(dfBC),
      envelope: serializeTableData(dfEnvelope)
    };
  });

  return serializedData;
});

export const currentAnnularVelocitySelector = createSelector(currentProjectSelector, (currentProject) => {
  const dfVAnnular = get(currentProject, 'df_v_annular', {});
  return serializeTableData(dfVAnnular);
});

export const currentDragTorqueSelector = createSelector(currentProjectSelector, (currentProject) => {
  const dragTorqueById = get(currentProject, 'drag_torque_by_id', {});
  const casingPrograms = get(currentProject, 'casing_program', []);
  const casingIds = Object.keys(dragTorqueById);

  const serializedData = {};
  casingIds.forEach((id) => {
    const casingProgram = find(casingPrograms, { id });
    const casingType = get(casingProgram, 'casing_type', 'UNKNOWN');
    const setDepth = get(casingProgram, 'set_depth', null);
    const WOB = get(casingProgram, 'operation.WOB', null);
    const dcLength = get(casingProgram, 'drill_collar.length', null);
    const optionLabel = `${casingType} - ${setDepth}`;
    const dfPipe = dragTorqueById[id].drill_pipe;
    const dfCasing = dragTorqueById[id].casing;

    serializedData[id] = {
      id,
      optionLabel,
      drillPipe: serializeTableData(dfPipe),
      casing: serializeTableData(dfCasing),
      maxWOB: get(dragTorqueById, `${id}.max_WOB`, null),
      WOB,
      dcLength,
      neutralMd: get(dragTorqueById, `${id}.neutral_md`, null),
      neutralLength: get(dragTorqueById, `${id}.neutral_length`, null)
    };
  });

  return serializedData;
});

export const currentHydraulicSelector = createSelector(currentProjectSelector, (currentProject) => {
  const dfHydraulic = get(currentProject, 'df_hydraulic', {});
  return serializeTableData(dfHydraulic);
});

export const surveyDataSelector = createSelector(currentProjectSelector, (obj) => get(obj, 'p_table', []));

export const currentMethodSelector = createSelector(currentProjectSelector, (obj) => get(obj, 'directional_survey_method', undefined));

export const colorAttrSelector = createSelector(currentProjectSelector, (obj) => get(obj, 'color_attr', null));

export const colorStopsSelector = createSelector(currentProjectSelector, (obj) => get(obj, 'color_stops', null));

export const colorAttrMinSelector = createSelector(colorAttrSelector, surveyDataSelector, (colorAttr, rowData) => {
  const attrMin = min(rowData.map((d) => d[colorAttr]));
  return attrMin;
});

export const colorAttrMaxSelector = createSelector(colorAttrSelector, surveyDataSelector, (colorAttr, rowData) => {
  const attrMax = max(rowData.map((d) => d[colorAttr]));
  return attrMax;
});

// COMPARE CASES SELECTOR
// ==========================

export const dataByProjectIdSelector = createCachedSelector(
  projectsByIdSelector,
  (state, projectId) => projectId,
  (projectsById, projectId) => {
    // Survey Data
    const rowData = get(projectsById, `${projectId}.p_table`, []);
    const colorAttr = get(projectsById, `${projectId}.color_attr`, 'z');
    const colorStops = get(projectsById, `${projectId}.color_stops`, null);
    const name = get(projectsById, `${projectId}.name`, null);

    // Chart Data
    const colorAttrData = rowData.map((d) => d[colorAttr]);
    const x = rowData.map((d) => d.x);
    const y = rowData.map((d) => d.y);
    const z = rowData.map((d) => d.z);
    const minColorAttr = min(colorAttrData);
    const maxColorAttr = max(colorAttrData);
    const colorData = [];

    if (!isEmpty(colorStops)) {
      const offsets = map(colorStops, 'offset');
      const colors = map(colorStops, 'color');
      const colorScale = d3.scale.linear().domain(offsets).range(colors).interpolate(d3.interpolateRgb);
      colorAttrData.forEach((value) => {
        let color = '#0000ff';
        if (maxColorAttr > minColorAttr && colorScale) {
          const offset = (value - minColorAttr) / (maxColorAttr - minColorAttr);
          color = colorScale(offset);
        }
        colorData.push(color);
      });
    }

    return {
      rowData,
      x,
      y,
      z,
      maxColorAttr,
      minColorAttr,
      name,
      colorData
    };
  }
)(
  (state, projectId) => projectId // Cache selectors by projectId
);

// CURRENT SENSITIVITY SELECTORS
// ==========================

export const currentSensitivityIdSelector = createSelector(selectedSensitivityIdsSelector, (selectedIds) => {
  if (selectedIds.length > 0) {
    return selectedIds[0];
  }
  return null;
});

export const currentSensitivitySelector = createSelector(
  sensitivitiesByIdSelector,
  currentSensitivityIdSelector,
  (byId, id) => byId[id] || undefined
);

export const sensitivitiesSelector = createSelector(sensitivityIdsSelector, sensitivitiesByIdSelector, (ids, byId) =>
  ids.map((id) => byId[id])
);

export const sensitivitiesBySelectedCasingIdSelector = createSelector(
  sensitivitiesSelector,
  selectedCasingIdsSelector,
  (sensitivities, selectedCasingIds) => sensitivities.filter((sens) => selectedCasingIds.includes(sens.casing_id))
);

export const currentHScenarioSelector = createSelector(currentSensitivitySelector, (sensitivity) => {
  const df = get(sensitivity, 'df_h_scenario', {});
  return serializeTableData(df);
});

export const currentHAnnularSelector = createSelector(currentSensitivitySelector, (sensitivity) => {
  const df = get(sensitivity, 'df_h_annular', {});
  return serializeTableData(df);
});

export const currentHHydraulicSelector = createSelector(currentSensitivitySelector, (sensitivity) => {
  const df = get(sensitivity, 'df_h_hydraulic', {});
  return serializeTableData(df);
});

export const currentSensitivitySummarySelector = createSelector(
  currentHScenarioSelector,
  currentHHydraulicSelector,
  (scenario, hydraulic) => {
    const scenarioData = get(scenario, 'rowData', []);
    const hydraulicData = get(hydraulic, 'rowData', []);
    const scenarioColDefs = get(scenario, 'columnDefs', []);
    const hydraulicColDefs = get(hydraulic, 'columnDefs', []);
    const scenarioPinnedData = get(scenario, 'pinnedTopRowData', []);
    const hydraulicPinnedData = get(hydraulic, 'pinnedTopRowData', []);
    const rowById = {};
    scenarioData.forEach((r) => {
      const rowId = `id-${r.scenario}`;
      rowById[rowId] = {
        param_1: r.param_1,
        param_2: r.param_2,
        param_3: r.param_3
      };
    });
    const summaryData = hydraulicData.map((r) => {
      const rowId = `id-${r.scenario}`;
      const row = rowById[rowId];
      return {
        ...r,
        ...row
      };
    });

    let columnDefs = [];

    if (scenarioColDefs.length > 0) {
      columnDefs = [...hydraulicColDefs, scenarioColDefs[1], scenarioColDefs[2], scenarioColDefs[3]];
    }

    return {
      columnDefs,
      rowData: summaryData,
      pinnedTopRowData: [{ ...scenarioPinnedData[0], ...hydraulicPinnedData[0] }]
    };
  }
);

export const currentCDScenarioSelector = createSelector(currentSensitivitySelector, (sensitivity) => {
  const df = get(sensitivity, 'df_cd_scenario', {});
  return serializeTableData(df);
});

export const currentCDEnvelopeSelector = createSelector(currentSensitivitySelector, (sensitivity) => {
  const df = get(sensitivity, 'df_cd_envelope', {});
  return serializeTableData(df);
});

export const currentCDBurstCollapseSelector = createSelector(currentSensitivitySelector, (sensitivity) => {
  const df = get(sensitivity, 'df_cd_bc', {});
  return serializeTableData(df);
});

export const currentDTScenarioSelector = createSelector(currentSensitivitySelector, (sensitivity) => {
  const df = get(sensitivity, 'df_dnt_scenario', {});
  return serializeTableData(df);
});

export const currentDTDrillPipeSelector = createSelector(currentSensitivitySelector, (sensitivity) => {
  const df = get(sensitivity, 'df_dnt_drill_pipe', {});
  return serializeTableData(df);
});

export const currentDTCasingSelector = createSelector(currentSensitivitySelector, (sensitivity) => {
  const df = get(sensitivity, 'df_dnt_casing', {});
  return serializeTableData(df);
});

export const currentDTSummarySelector = createSelector(currentSensitivitySelector, (sensitivity) => {
  const df = get(sensitivity, 'df_dnt_summary', {});
  return serializeTableData(df);
});

// Unuse
export const currentHydraulicProgramSelector = createSelector(currentProjectSelector, (currentProject) => {
  // const casingPrograms = get(currentProject, 'casing_program', []);
  const mudPrograms = get(currentProject, 'mud_program', []);
  const hydraulicByMudId = get(currentProject, 'hydraulic_by_mud_id', {});

  const hydraulicProgram = [];
  mudPrograms.forEach((mud) => {
    const mudId = get(mud, 'id', null);
    // const casingId = get(mud, 'casing_id', null);
    const oneRow = {
      drilled_depth: get(mud, 'drilled_depth', null),
      q: get(mud, 'q', null),
      den: get(mud, 'den', null),
      vis: get(mud, 'vis', null),
      fluid_model: get(mud, 'fluid_model', null),
      ty: get(mud, 'ty', null),
      K: get(mud, 'K', null),
      n: get(mud, 'n', null),
      drilled_tvd: get(hydraulicByMudId, `${mudId}.drilled_tvd`, null),
      V_kick: get(hydraulicByMudId, `${mudId}.V_kick`, null),
      h_kick: get(hydraulicByMudId, `${mudId}.h_kick`, null),
      P_surf: get(hydraulicByMudId, `${mudId}.P_surf`, null)
    };
    hydraulicProgram.push(oneRow);
  });

  const columns = [
    {
      field: 'drilled_depth',
      headerName: 'Drilled Depth (ft)'
    },
    {
      field: 'q',
      headerName: 'Flow Rate (gpm)'
    },
    {
      field: 'den',
      headerName: 'Fluid Density (ppg)'
    },
    {
      field: 'vis',
      headerName: 'Fluid Viscosity (cp)'
    },
    {
      field: 'fluid_model',
      headerName: 'Fluid Model'
    },
    {
      field: 'ty',
      headerName: 'Yield ty'
    },
    {
      field: 'K',
      headerName: 'K'
    },
    {
      field: 'n',
      headerName: 'n'
    },
    {
      field: 'drilled_tvd',
      headerName: 'Drilled TVD (ft)'
    },
    {
      field: 'V_kick',
      headerName: 'Kick Volume'
    },
    {
      field: 'h_kick',
      headerName: 'h_kick'
    },
    {
      field: 'P_surf',
      headerName: 'P_surf'
    }
  ];

  const columnDefs = columns.map((d) => ({
    field: d.field,
    headerName: d.headerName,
    tooltipField: d.field,
    headerTooltip: d.headerName,
    minWidth: 80
  }));

  return {
    columnDefs,
    rowData: hydraulicProgram
  };
});
