import React from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { makeStyles } from '@mui/styles';
import { Button, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';

// project imports
import { DEFAULT_PATH } from 'config';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';

// assets
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import imageUnauthorize from 'assets/images/maintenance/img-unauthorize.svg';

// style constant
const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 'calc(100vh - 48px)',
    // minHeight: 'calc(100vh - 48px)',
    flexGrow: 1
  },
  errorImg: {
    maxWidth: '360px',
    margin: '0 auto',
    position: 'relative',
    width: 500
  },
  errorContent: {
    maxWidth: '350px',
    margin: '0 auto',
    textAlign: 'center'
  },
  errorBlock: {
    // minHeight: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  imgBlock: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    animation: '3s bounce ease-in-out infinite'
  }
});

// ==============================|| ERROR PAGE ||============================== //

const UnauthorizeError = () => {
  const classes = useStyles();

  return (
    <Card className={classes.errorBlock}>
      <CardContent>
        <Grid container justifyContent="center" spacing={gridSpacing}>
          <Grid item xs={12}>
            <div className={classes.errorImg}>
              <CardMedia component="img" image={imageUnauthorize} title="Slider5 image" className={classes.errorImg} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.errorContent}>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                  <Typography variant="h1" component="div">
                    Authorization required
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">Sorry, you don&apos;t have access to this page. </Typography>
                </Grid>
                <Grid item xs={12}>
                  <AnimateButton>
                    <Button variant="contained" size="large" component={Link} to={DEFAULT_PATH}>
                      <HomeTwoToneIcon sx={{ fontSize: '1.3rem', mr: 0.75 }} /> Back to Home Page
                    </Button>
                  </AnimateButton>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UnauthorizeError;
