import React from 'react';
import get from 'lodash/get';

// material-ui
import { Typography } from '@mui/material';

// project imports
import useAuth from 'hooks/useAuth';
import NavGroup from './NavGroup';
import menuItem from 'menu-items';

// ===========================|| SIDEBAR MENU LIST ||=========================== //

const MenuList = () => {
  const { user } = useAuth();
  const roles = get(user, 'roles', []);

  // TODO: Dynamically build top menu
  // console.log(user);

  const navItems = menuItem.items.map((item) => {
    if (item.id === 'admin-dashboard' && !roles.includes('admin')) {
      return null;
    }
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return navItems;
};

export default MenuList;
