import React from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { makeStyles } from '@mui/styles';
import { ListItem, ListItemButton, ListItemIcon } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';

// style constant
const useStyles = makeStyles({
  itemButton: {
    padding: '0!important',
    margin: '0!important'
  }
});

// ===========================|| PROFILE MENU ||=========================== //

const DevButtonSection = () => {
  const store = useSelector((state) => state);

  const logData = () => {
    console.log('================ LOGS ================');
    console.log('STORE => ', store);
    console.log('======================================');
  };

  const classes = useStyles();
  return (
    <ListItem dense button>
      <ListItemButton className={classes.itemButton} color="primary" size="small" onClick={logData}>
        <ListItemIcon>
          <PrintIcon className={classes.itemButton} color="black" fontSize="small" text="Print" />
        </ListItemIcon>
        Print Store
      </ListItemButton>
    </ListItem>
  );
};

export default DevButtonSection;
