// INITIAL STATE
// ==========================================================================

export const initialState = {
  currentLayout: 'Main', // 'project' || 'sensitivity'

  // rod catalog table data
  rodCatalogTableData: {},

  // Project
  selectedProjectIds: [],
  projectIds: [],
  projectsById: {},

  // Sensitivity
  selectedSensitivityIds: [],
  sensitivityIds: [],
  sensitivitiesById: {},

  selectedScenarioIds: [],

  settings: {}
};

export default initialState;
