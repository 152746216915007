import produce from 'immer';

// action - state management
import { ACCOUNT_INITIALIZE, LOGIN, LOGOUT, SAVE_PROFILE } from './types';
// ===========================|| ACCOUNT REDUCER ||=========================== //

const accountReducer = (state, action) => {
  switch (action.type) {
    case ACCOUNT_INITIALIZE: {
      const { isLoggedIn, user } = action.payload;
      return {
        ...state,
        isLoggedIn,
        isInitialized: true,
        user
      };
    }
    case LOGIN: {
      const { user } = action.payload;
      return {
        ...state,
        isLoggedIn: true,
        user
      };
    }
    case LOGOUT: {
      window.location.href = '/login';
      return {
        ...state,
        isLoggedIn: false,
        user: null
      };
    }
    case SAVE_PROFILE: {
      const { profile } = action.payload;
      return produce(state, (draft) => {
        draft.user.profile = profile;
      });
    }

    default: {
      return { ...state };
    }
  }
};

export default accountReducer;
