// material-ui
import { Typography, Grid, TableContainer } from '@mui/material';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import { gridSpacing } from 'store/constant';
import useChartConfig from 'hooks/useChartConfig';

// Components
import FontFamily from '../../components/FontFamily';
import FontSize from '../../components/FontSize';
import FontStyle from '../../components/FontStyle';

const LegendSetting = () => {
  const {
    // legend
    legendFontFamily,
    onChangeLegendFontFamily,
    legendFontSize,
    onChangeLegendFontSize,
    legendFontStyle,
    onChangeLegendFontStyle
  } = useChartConfig();

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item lg={2}>
        <Typography fontSize={17} fontWeight={500} sx={{ pt: 2 }}>
          Legend
        </Typography>
      </Grid>
      <Grid item lg={10} xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item lg={12} xs={12}>
            <SubCard>
              <Grid container spacing={gridSpacing}>
                <Grid item lg={12} xs={12} style={{ height: '50px' }}>
                  <Typography fontFamily={legendFontFamily} fontSize={legendFontSize} fontStyle={legendFontStyle}>
                    Label
                  </Typography>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TableContainer>
                    <FontFamily uniqueKey="legendFontFamily" fontFamily={legendFontFamily} onChangeFontFamily={onChangeLegendFontFamily} />
                    <FontSize
                      uniqueKey="legendFontSize"
                      fontSize={legendFontSize}
                      onChangeFontSize={onChangeLegendFontSize}
                      min={9}
                      max={16}
                    />
                    <FontStyle uniqueKey="legendFontStyle" fontStyle={legendFontStyle} onChangeFontStyle={onChangeLegendFontStyle} />
                  </TableContainer>
                </Grid>
              </Grid>
            </SubCard>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LegendSetting;
