import { useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import find from 'lodash/find';
import { useNavigate } from 'react-router-dom';
import UnauthorizeError from 'pages/maintenance/UnauthorizeError';

// project imports
import useAuth from 'hooks/useAuth';
// ===========================|| SOFT GUARD ||=========================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const SoftGuard = (props) => {
  const { softToGuard, children } = props;

  const navigate = useNavigate();
  const { user, logout, checkToken } = useAuth();

  // Authentication
  useEffect(() => {
    if (!user) {
      logout();
      return;
    }
    checkToken();
  }, [navigate, logout, checkToken, user]);

  // Authorization
  const authorizeSoftware = () => {
    const userSoftwares = get(user, 'softwares', []);
    const software = find(userSoftwares, (o) => o.id === softToGuard);
    if (software) {
      const today = new Date();
      const expiredDate = new Date(software.expiredDate);
      if (today < expiredDate) {
        return true;
      }
    }
    const username = get(user, 'username', null);
    if (username === 'ppsc@ppscnm.com') {
      return true;
    }
    return false;
  };

  const isAuthorized = authorizeSoftware();

  if (!isAuthorized) {
    return <UnauthorizeError />;
  }

  return children;
};

SoftGuard.propTypes = {
  children: PropTypes.node
};

export default SoftGuard;
