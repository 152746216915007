import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash';

// material-ui
import { makeStyles, useTheme } from '@mui/styles';
import {
  // Avatar,
  CardContent,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  // InputAdornment,
  List,
  ListItemIcon,
  ListItemText,
  // OutlinedInput,
  Paper,
  Popper,
  Typography
} from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import useAuth from 'hooks/useAuth';
import { UPDATE_UNSAVED_STATUS } from 'store/types';
import { useConfirm } from 'ui-component/extended/ConfirmDialog';
import useConfig from 'hooks/useConfig';

// assets
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import InvertColorsIcon from '@mui/icons-material/InvertColors';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

// import TestDialog from './dialog';
import ChartSettingDialog from 'pages/chart_setting';

// style const
const useStyles = makeStyles((theme) => ({
  navContainer: {
    width: '100%',
    maxWidth: '350px',
    minWidth: '300px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px',
    [theme.breakpoints.down('lg')]: {
      minWidth: '100%'
    }
  },
  headerAvatar: {
    cursor: 'pointer',
    ...theme.typography.mediumAvatar,
    margin: '8px 0 8px 8px !important'
  },
  profileChip: {
    height: '36px',
    alignItems: 'center',
    borderRadius: '27px',
    transition: 'all .2s ease-in-out',
    borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
    '&[aria-controls="menu-list-grow"], &:hover': {
      borderColor: theme.palette.primary.main,
      background: `${theme.palette.primary.main} !important`,
      color: theme.palette.primary.light,
      '& svg': {
        stroke: theme.palette.primary.light
      }
    }
  },
  profileLabel: {
    lineHeight: 0,
    padding: '12px'
  },
  listItem: {
    marginTop: '5px'
  },
  cardContent: {
    padding: '16px !important'
  },
  card: {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark[800] : theme.palette.primary.light,
    marginBottom: '16px',
    marginTop: '16px'
  },
  searchControl: {
    width: '100%',
    paddingRight: '8px',
    paddingLeft: '16px',
    marginBottom: '16px',
    marginTop: '16px'
  },
  startAdornment: {
    fontSize: '1rem',
    color: theme.palette.grey[500]
  },
  flex: {
    display: 'flex'
  },
  name: {
    marginLeft: '4px'
    // fontWeight: 400
  },
  ScrollHeight: {
    height: '100%',
    maxHeight: 'calc(100vh - 250px)',
    overflowX: 'hidden'
  },
  badgeWarning: {
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.warning.dark,
    color: '#fff'
  }
}));

// ===========================|| PROFILE MENU ||=========================== //

const ProfileSection = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { borderRadius } = useConfig();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const { logout, user } = useAuth();

  // REDUX BINDING
  // =================================================
  const dispatch = useDispatch();
  const withUnsavedWork = useSelector((state) => get(state, 'global.withUnsavedWork', false));
  const unsavedWorkMsg = useSelector((state) => get(state, 'global.unsavedWorkMsg', ''));

  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const [open, setOpen] = React.useState(false);
  const [openChartSetting, setOpenChartSetting] = React.useState(false);
  const anchorRef = React.useRef(null);
  const prevOpen = React.useRef(open);

  const { username, profile, roles } = user;
  const isAdmin = roles.includes('admin');

  let name = `${profile.firstName} ${profile.lastName}`;
  name = name.trim();
  if (name === '') {
    [name] = username.split('@');
  }
  const title = `PPSC's ${roles[0]}`;

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = '') => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== '') {
      navigate(route);
    }
  };

  const handleLogout = async () => {
    if (withUnsavedWork) {
      confirm({ title: 'Are you sure ?', description: unsavedWorkMsg })
        .then(() => {
          dispatch({ type: UPDATE_UNSAVED_STATUS, withUnsavedWork: false });
          logout();
        })
        .catch(() => {
          /* ... */
        });
    } else {
      logout();
    }
  };

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Chip
        sx={{
          height: '36px',
          alignItems: 'center',
          borderRadius: '27px',
          transition: 'all .2s ease-in-out',
          borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
          backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            '& svg': {
              stroke: theme.palette.primary.light
            }
          },
          '& .MuiChip-label': {
            lineHeight: 0
          }
        }}
        label={<AccountCircleIcon stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions position="top-right" in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  <CardContent className={classes.cardContent}>
                    <Grid container direction="column" spacing={0}>
                      <Grid item className={classes.flex}>
                        <Typography variant="h4">Welcome, </Typography>
                        <Typography component="span" variant="h4" className={classes.name}>
                          {name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="subtitle2">{title}</Typography>
                      </Grid>
                    </Grid>
                    <Divider />
                    <List
                      component="nav"
                      sx={{
                        width: '100%',
                        maxWidth: 350,
                        minWidth: 300,
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: '10px',
                        [theme.breakpoints.down('md')]: {
                          minWidth: '100%'
                        },
                        '& .MuiListItemButton-root': {
                          mt: 0.5
                        }
                      }}
                    >
                      {isAdmin && (
                        <>
                          <ListItemButton
                            sx={{ borderRadius: `${borderRadius}px` }}
                            selected={selectedIndex === 0}
                            onClick={(event) => handleListItemClick(event, 0, '/admin/user-manager')}
                          >
                            <ListItemIcon>
                              <SupervisedUserCircleIcon stroke={1.5} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body2">User Manager</Typography>} />
                          </ListItemButton>
                          <ListItemButton
                            sx={{ borderRadius: `${borderRadius}px` }}
                            selected={selectedIndex === 10}
                            onClick={(event) => handleListItemClick(event, 10, '/admin/user-activity')}
                          >
                            <ListItemIcon>
                              <QueryStatsIcon stroke={1.5} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body2">User Activity</Typography>} />
                          </ListItemButton>
                          <ListItemButton
                            sx={{ borderRadius: `${borderRadius}px` }}
                            selected={selectedIndex === 10}
                            onClick={(event) => handleListItemClick(event, 10, '/admin/email-activity')}
                          >
                            <ListItemIcon>
                              <QueryStatsIcon stroke={1.5} size="1.3rem" />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="body2">Email Activity</Typography>} />
                          </ListItemButton>
                        </>
                      )}
                      <ListItemButton
                        sx={{ borderRadius: `${borderRadius}px` }}
                        selected={selectedIndex === 1}
                        onClick={(event) => handleListItemClick(event, 1, '/user/account')}
                      >
                        <ListItemIcon>
                          <ManageAccountsIcon stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="body2">Account Settings</Typography>} />
                      </ListItemButton>
                      <ListItemButton
                        sx={{ borderRadius: `${borderRadius}px` }}
                        selected={selectedIndex === 2}
                        onClick={(event) => handleListItemClick(event, 2, '/user/theme-settings')}
                      >
                        <ListItemIcon>
                          <InvertColorsIcon stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="body2">Theme Settings</Typography>} />
                      </ListItemButton>
                      <ListItemButton
                        sx={{ borderRadius: `${borderRadius}px` }}
                        selected={selectedIndex === 3}
                        onClick={(event) => {
                          setOpen(false);
                          setSelectedIndex(3);
                          setOpenChartSetting(true);
                        }}
                      >
                        <ListItemIcon>
                          <AutoGraphIcon stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="body2">Chart Settings</Typography>} />
                      </ListItemButton>
                      <ListItemButton
                        className={classes.listItem}
                        sx={{ borderRadius: `${borderRadius}px` }}
                        selected={selectedIndex === 4}
                        onClick={handleLogout}
                      >
                        <ListItemIcon>
                          <LogoutIcon stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                      </ListItemButton>
                    </List>
                  </CardContent>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
      <ChartSettingDialog openDialog={openChartSetting} setOpenDialog={setOpenChartSetting} />
    </>
  );
};

export default ProfileSection;
