import { initialState, getDefaultCase } from './initialState';
import * as constants from './constants';
import produce from 'immer';
import get from 'lodash/get';
import { v4 as UIDV4 } from 'uuid';

// ===========================|| ACCOUNT REDUCER ||=========================== //

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.SELECT_CASES:
      return produce(state, (draft) => {
        const ids = action.payload;
        draft.selectedCaseIds = ids;
      });

    case constants.INIT_CASES:
      return produce(state, (draft) => {
        const { casesById, caseIds } = action.payload;
        if (Array.isArray(caseIds)) {
          caseIds.forEach((id) => {
            if (!draft.caseIds.includes(id)) {
              draft.caseIds.push(id);
            }
            draft.casesById[id] = casesById[id];
          });
        }
      });

    case constants.ADD_CASE:
      return produce(state, (draft) => {
        // Logic to add index to case name
        const name = action.payload;
        const newCase = getDefaultCase(name);
        const { id } = newCase;
        draft.casesById[id] = newCase;
        draft.caseIds.push(id);
        // Insert new case to select
        draft.selectedCaseIds.unshift(id);
      });

    case constants.DUPLICATE_CASES:
      return produce(state, (draft) => {
        const { ids } = action.payload;
        if (Array.isArray(ids)) {
          ids.forEach((id) => {
            // Create duplicate case from reference case
            const dupCaseId = UIDV4();
            const createdDate = Date.now();
            const refCase = draft.casesById[id];
            const name = get(refCase, 'name', '');
            const dupCase = {
              ...refCase,
              id: dupCaseId,
              name: `${name} - Copy`,
              createdDate,
              modifiedDate: createdDate
            };
            // Save duplicate case to the store
            draft.caseIds.push(dupCaseId);
            draft.selectedCaseIds.unshift(dupCaseId);
            draft.casesById[dupCaseId] = dupCase;
          });
        }
      });

    case constants.REMOVE_CASE:
      return produce(state, (draft) => {
        const { id } = action.payload;
        // Delete in obj
        delete draft.casesById[id];
        // Delete in order array
        const idx = draft.caseIds.findIndex((caseId) => caseId === id);
        if (idx !== -1) draft.caseIds.splice(idx, 1);
        // Delete in selectedCaseIds
        const selectId = draft.selectedCaseIds.findIndex((caseId) => caseId === id);
        if (selectId !== -1) draft.selectedCaseIds.splice(selectId, 1);
      });

    case constants.REMOVE_CASES:
      return produce(state, (draft) => {
        const { ids } = action.payload;
        if (Array.isArray(ids)) {
          ids.forEach((id) => {
            // Delete in obj
            delete draft.casesById[id];
            // Delete in order array
            const idx = draft.caseIds.findIndex((caseId) => caseId === id);
            if (idx !== -1) draft.caseIds.splice(idx, 1);
            // Delete in selectedCaseIds
            const selectId = draft.selectedCaseIds.findIndex((caseId) => caseId === id);
            if (selectId !== -1) draft.selectedCaseIds.splice(selectId, 1);
          });
        }
      });

    case constants.UPDATE_CASE:
      return produce(state, (draft) => {
        const { caseId, key, value } = action.payload;
        const modifiedDate = Date.now();
        draft.casesById[caseId][key] = value;
        draft.casesById[caseId].modifiedDate = modifiedDate;
      });

    case constants.UPDATE_CASE_1:
      return produce(state, (draft) => {
        const { caseId, key1, key, value } = action.payload;
        const modifiedDate = Date.now();
        draft.casesById[caseId][key1][key] = value;
        draft.casesById[caseId].modifiedDate = modifiedDate;
      });

    case constants.UPDATE_CASE_2:
      return produce(state, (draft) => {
        const { caseId, key2, key1, key, value } = action.payload;
        const modifiedDate = Date.now();
        draft.casesById[caseId][key2][key1][key] = value;
        draft.casesById[caseId].modifiedDate = modifiedDate;
      });

    case constants.UPDATE_SETTINGS:
      return produce(state, (draft) => {
        const { settings } = action.payload;
        draft.settings = settings;
      });

    default:
      return state;
  }
};

export default reducer;
