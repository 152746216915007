import account from './account';
import admin from './admin';
import software from './software';
import browserSetting from './browserSetting';

// ===========================|| MENU ITEMS ||=========================== //

const menuItems = {
  items: [software, admin, account, browserSetting]
};

export default menuItems;
