// material-ui
import { Typography, Grid, TableContainer } from '@mui/material';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import { gridSpacing } from 'store/constant';
import useChartConfig from 'hooks/useChartConfig';

// Components
import FontFamily from '../../components/FontFamily';
import FontSize from '../../components/FontSize';
import FontStyle from '../../components/FontStyle';

const TitleSetting = () => {
  const {
    // title
    titleFontFamily,
    onChangeTitleFontFamily,
    titleFontSize,
    onChangeTitleFontSize,
    titleFontStyle,
    onChangeTitleFontStyle
  } = useChartConfig();

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item lg={2}>
        <Typography fontSize={17} fontWeight={500} sx={{ pt: 2 }}>
          Title
        </Typography>
      </Grid>
      <Grid item lg={10} xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item lg={12} xs={12}>
            <SubCard>
              <Grid container spacing={gridSpacing}>
                <Grid item lg={12} xs={12} style={{ height: '50px' }}>
                  <Typography fontFamily={titleFontFamily} fontSize={titleFontSize} fontStyle={titleFontStyle}>
                    Label
                  </Typography>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TableContainer>
                    <FontFamily uniqueKey="titleFontFamily" fontFamily={titleFontFamily} onChangeFontFamily={onChangeTitleFontFamily} />
                    <FontSize
                      uniqueKey="titleFontSize"
                      fontSize={titleFontSize}
                      onChangeFontSize={onChangeTitleFontSize}
                      min={12}
                      max={22}
                    />
                    <FontStyle uniqueKey="titleFontStyle" fontStyle={titleFontStyle} onChangeFontStyle={onChangeTitleFontStyle} />
                  </TableContainer>
                </Grid>
              </Grid>
            </SubCard>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TitleSetting;
