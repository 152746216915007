import axios from 'axios';
import TokenService from 'utils/auth/token.service';
import getServiceUrl from 'utils/service-url';

const axiosInstance = axios.create({
  // baseURL: 'http://localhost:5000/',
  baseURL: getServiceUrl('well-completion'),
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // for Spring Boot back-end
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
