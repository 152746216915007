class TokenService {
  // user keep accessToken, refreshToken only
  getLocalRefreshToken = () => {
    const user = JSON.parse(localStorage.getItem('ppsc-user'));
    return user?.refreshToken;
  };

  getLocalAccessToken = () => {
    const user = JSON.parse(localStorage.getItem('ppsc-user'));
    return user?.accessToken;
  };

  updateLocalAccessToken = (token) => {
    const user = JSON.parse(localStorage.getItem('ppsc-user'));
    user.accessToken = token;
    localStorage.setItem('ppsc-user', JSON.stringify(user));
  };

  getUser = () => {
    JSON.parse(localStorage.getItem('ppsc-user'));
  };

  setUser = (user) => {
    localStorage.setItem('ppsc-user', JSON.stringify(user));
  };

  removeUser = () => {
    localStorage.removeItem('ppsc-user');
  };

  removeApp = () => {
    localStorage.removeItem('ppsc-app');
  };
}

export default new TokenService();
