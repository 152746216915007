export const NAMESPACE = 'well-completion/well-trajectory';

// CASE MANAGER
// ==========================================================================

export const INIT_CASES = `${NAMESPACE}/INIT_CASES`;
export const SELECT_CASES = `${NAMESPACE}/SELECT_CASES`;
export const ADD_CASE = `${NAMESPACE}/ADD_CASE`;
export const DUPLICATE_CASES = `${NAMESPACE}/DUPLICATE_CASES`;
export const REMOVE_CASE = `${NAMESPACE}/REMOVE_CASE`;
export const REMOVE_CASES = `${NAMESPACE}/REMOVE_CASES`;
export const UPDATE_CASE = `${NAMESPACE}/UPDATE_CASE`;
export const UPDATE_CASE_1 = `${NAMESPACE}/UPDATE_CASE_1`;
export const UPDATE_CASE_2 = `${NAMESPACE}/UPDATE_CASE_2`;
export const UPDATE_SETTINGS = `${NAMESPACE}/UPDATE_SETTINGS`;
