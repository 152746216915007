import React from 'react';
// material-ui
import { useTheme, makeStyles } from '@mui/styles';
import { Paper, Button, Dialog, DialogTitle, DialogActions, DialogContent, Grid } from '@mui/material';

import Draggable from 'react-draggable';

import { gridSpacing } from 'store/constant';
import XAxisSetting from './containers/XAxisSetting';
import YAxisSetting from './containers/YAxisSetting';
import TitleSetting from './containers/TitleSetting';
import LegendSetting from './containers/LegendSetting';

const useStyles = makeStyles({
  dialog: {
    position: 'absolute',
    left: 10,
    top: 20
  }
});
// draggable
const PaperComponent = (props) => (
  <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
    <Paper {...props} />
  </Draggable>
);

const ChartSettingDialog = ({ openDialog, setOpenDialog }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      classes={{
        paper: classes.dialog
      }}
    >
      {openDialog && (
        <>
          <DialogTitle>Chart Settings</DialogTitle>
          <DialogContent>
            <Grid container spacing={gridSpacing}>
              <Grid item lg={6} xs={12}>
                <TitleSetting />
              </Grid>
              <Grid item lg={6} xs={12}>
                <LegendSetting />
              </Grid>
              <Grid item lg={6} xs={12}>
                <XAxisSetting />
              </Grid>
              <Grid item lg={6} xs={12}>
                <YAxisSetting />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button sx={{ color: theme.palette.error.dark }} autoFocus onClick={() => setOpenDialog(false)} color="secondary">
              Close
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default ChartSettingDialog;
