import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import get from 'lodash/get';

// project imports
import { useEffect } from 'react';
import useAuth from 'hooks/useAuth';

// ===========================|| ROLE GUARD ||=========================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const RoleGuard = (props) => {
  const { roleToGuard, children } = props;
  const navigate = useNavigate();
  const { user, logout, checkToken } = useAuth();
  const roles = get(user, 'roles', []);
  const isAuthorized = roles.includes(roleToGuard);

  useEffect(() => {
    if (roleToGuard !== 'user' && !isAuthorized) {
      logout();
      return;
    }
    checkToken();
  }, [navigate, logout, checkToken, roleToGuard, isAuthorized]);

  return children;
};

RoleGuard.propTypes = {
  children: PropTypes.node
};

export default RoleGuard;
