import * as constants from './constants';
import * as selectors from './selectors';
import * as utils from './utils';
import * as backend from './backend';
import reducer from './reducer';

const injectableReducer = { [constants.NAMESPACE]: reducer };
export { constants, reducer, selectors, injectableReducer, utils, backend };

export default reducer;
