export const NAMESPACE = 'well-completion/well-insight';

// CASE MANAGER
// ==========================================================================

export const INIT_PROJECTS = `${NAMESPACE}/INIT_PROJECTS`;
export const SELECT_PROJECTS = `${NAMESPACE}/SELECT_PROJECTS`;
export const ADD_PROJECT = `${NAMESPACE}/ADD_PROJECT`;
export const REMOVE_PROJECT = `${NAMESPACE}/REMOVE_PROJECT`;
export const UPDATE_PROJECT = `${NAMESPACE}/UPDATE_PROJECT`;
export const UPDATE_SETTINGS = `${NAMESPACE}/UPDATE_SETTINGS`;
