export const NAMESPACE = 'well-completion/completion-design';

// PROJECT MANAGER
// ==========================================================================

export const INIT_PROJECTS = `${NAMESPACE}/INIT_PROJECTS`;
export const SELECT_PROJECTS = `${NAMESPACE}/SELECT_PROJECTS`;
export const ADD_PROJECT = `${NAMESPACE}/ADD_PROJECT`;
export const REMOVE_PROJECT = `${NAMESPACE}/REMOVE_PROJECT`;
export const UPDATE_PROJECT = `${NAMESPACE}/UPDATE_PROJECT`;

// CASING SELECTION
// ==========================================================================

export const SELECT_CASINGS = `${NAMESPACE}/SELECT_CASINGS`;

// SENSITIVITY MANAGER
// ==========================================================================

export const INIT_SENSITIVITIES = `${NAMESPACE}/INIT_SENSITIVITIES`;
export const SELECT_SENSITIVITIES = `${NAMESPACE}/SELECT_SENSITIVITIES`;
export const ADD_SENSITIVITY = `${NAMESPACE}/ADD_SENSITIVITY `;
export const REMOVE_SENSITIVITY = `${NAMESPACE}/REMOVE_SENSITIVITY`;
export const UPDATE_SENSITIVITY = `${NAMESPACE}/UPDATE_SENSITIVITY`;

// CASING SCENARIOS
// ==========================================================================

export const SELECT_H_SCENARIOS = `${NAMESPACE}/SELECT_H_SCENARIOS`;
export const SELECT_CD_SCENARIOS = `${NAMESPACE}/SELECT_CD_SCENARIOS`;
export const SELECT_DNT_SCENARIOS = `${NAMESPACE}/SELECT_DNT_SCENARIOS`;

// SETTINGS
// ==========================================================================

export const UPDATE_SETTINGS = `${NAMESPACE}/UPDATE_SETTINGS`;

// CONTROLLER SELECTION
// ==========================================================================

export const SELECT_CURRENT_LAYOUT = `${NAMESPACE}/SELECT_CURRENT_LAYOUT`;
