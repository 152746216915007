import api from '../../api';

const BASE_URL = '/acid-gas-injection/project';

export const fetchProjectList = async (owner) => {
  // GET USER LIST FROM BACKEND
  const response = await api.get(`${BASE_URL}?owner=${owner}`);
  return response.data;
};

export const readProject = async (projectId) => {
  // GET USER LIST FROM BACKEND
  const response = await api.get(`${BASE_URL}/${projectId}`);
  return response.data;
};

export const updateProject = async (id, project) => {
  // GET USER LIST FROM BACKEND
  const response = await api.put(`${BASE_URL}/${id}`, project);
  return response.data;
};

export const addProject = async (project) => {
  // CALL BACKEND TO ADD USER username
  const response = await api.post(`${BASE_URL}`, project);
  return response.data;
};

export const duplicateProject = async (project) => {
  // CALL BACKEND TO ADD USER username
  const response = await api.post(`${BASE_URL}`, project);
  return response.data;
};

export const removeProject = async (id) => {
  // CALL BACKEND TO REMOVE USER userId
  const response = await api.delete(`${BASE_URL}/${id}`);
  return response.data;
};
