export const INPUT_COLS = ['md', 'inclination', 'azimuth'];

export const EMPTY_ROW = {
  id: 'id',
  md: 0,
  inclination: 0,
  azimuth: 0,
  x: 0,
  y: 0,
  z: 0,
  dx: 0,
  dy: 0,
  dz: 0,
  departure: 0,
  dls_2d: 0,
  dls_3d: 0
};

export const COLS_BY_ID = {
  id: {
    id: 'id',
    name: 'Action',
    description: 'Create and Delete Row',
    minWidth: 90,
    width: 90,
    unit: null
  },
  md: {
    id: 'md',
    name: 'MD',
    label: 'Measure Depth',
    description: 'Measure Depth',
    minWidth: 70,
    width: 70,
    unit: {
      Field: 'ft',
      SI: 'm'
    }
  },
  inclination: {
    id: 'inclination',
    name: 'Incl',
    label: 'Inclination Angle',
    description: 'Inclination Angle',
    minWidth: 70,
    width: 70,
    unit: {
      Field: 'deg',
      SI: 'deg'
    }
  },
  azimuth: {
    id: 'azimuth',
    name: 'Azimuth',
    label: 'Azimuth Angle',
    description: 'Azimuth Angle',
    minWidth: 70,
    width: 70,
    unit: {
      Field: 'deg',
      SI: 'deg'
    }
  },
  z: {
    id: 'z',
    name: 'z TVD',
    label: 'z TVD',
    description: 'Deviation in Vertical Direction',
    minWidth: 70,
    width: 70,
    unit: {
      Field: 'ft',
      SI: 'm'
    }
  },
  x: {
    id: 'x',
    name: 'x +N/-S',
    label: 'x North/South',
    description: 'Deviation in North/South Direction. +N/-S',
    minWidth: 70,
    width: 70,
    unit: {
      Field: 'ft',
      SI: 'm'
    }
  },
  y: {
    id: 'y',
    name: 'y +E/-W',
    label: 'y East/West',
    description: 'Deviation in East/West Direction. +E/-W',
    minWidth: 70,
    width: 70,
    unit: {
      Field: 'ft',
      SI: 'm'
    }
  },
  dx: {
    id: 'dx',
    name: 'dX',
    label: 'dX East/West',
    description: 'Deviation in North Direction from Previous Point',
    minWidth: 70,
    width: 70,
    unit: {
      Field: 'ft',
      SI: 'm'
    }
  },
  dy: {
    id: 'dy',
    name: 'dY',
    label: 'dY North/South',
    description: 'Deviation in East Direction from Previous Point',
    minWidth: 70,
    width: 70,
    unit: {
      Field: 'ft',
      SI: 'm'
    }
  },
  dz: {
    id: 'dz',
    name: 'dZ',
    label: 'dZ Vertical',
    description: 'Deviation in Vertical Direction from Previous Point',
    minWidth: 70,
    width: 70,
    unit: {
      Field: 'ft',
      SI: 'm'
    }
  },
  departure: {
    id: 'departure',
    name: 'Departure',
    label: 'Horizontal Departure',
    description: 'Horizontal Departure',
    minWidth: 80,
    width: 80,
    unit: {
      Field: 'ft',
      SI: 'm'
    }
  },
  dls_2d: {
    id: 'dls_2d',
    name: '2D DLS',
    label: '2D Dogleg',
    description: '2D Dogleg Severity',
    minWidth: 80,
    width: 80,
    unit: {
      Field: 'deg/100ft',
      SI: 'deg/30m'
    }
  },
  dls_3d: {
    id: 'dls_3d',
    name: '3D DLS',
    label: '3D Dogleg',
    description: '3D Dogleg Severity',
    minWidth: 80,
    width: 80,
    unit: {
      Field: 'deg/100ft',
      SI: 'deg/30m'
    }
  }
};
