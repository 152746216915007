import api from 'utils/auth/emailing-api';

const EMAIL_URL = '/emailing';

export const sendEmail = async (toInfo) => {
  // GET USER LIST FROM BACKEND
  const response = await api.post(`${EMAIL_URL}/sendEmail`, toInfo);

  return response.data;
};

export const setEmailSenderAccount = async (senderAccount) => {
  // GET USER LIST FROM BACKEND
  const response = await api.post(`${EMAIL_URL}/setSenderAccount`, senderAccount);

  return response.data;
};

// ======================================================================================= //
// ================================= INFORM EXPIRATION =================================== //

export const setupSendingEmailToInformExpiration = async ({ isTest, checkingInterval, daysToExp1, daysToExp2, daysToExp3 }) => {
  const response = await api.post(`${EMAIL_URL}/addCheckSoftwareExpiration`, {
    is_test: isTest,
    checking_interval: checkingInterval,
    days_to_exp1: daysToExp1,
    days_to_exp2: daysToExp2,
    days_to_exp3: daysToExp3
  });
  return response.data;
};

export const removeCheckSoftwareExpiration = async () => {
  const response = await api.get(`${EMAIL_URL}/removeCheckSoftwareExpiration`);
  return response.data;
};

export const pauseCheckSoftwareExpiration = async () => {
  const response = await api.get(`${EMAIL_URL}/pauseCheckSoftwareExpiration`);
  return response.data;
};

export const resumeCheckSoftwareExpiration = async () => {
  const response = await api.get(`${EMAIL_URL}/resumeCheckSoftwareExpiration`);
  return response.data;
};

export const checkIfATestIsSet = async () => {
  const response = await api.get(`${EMAIL_URL}/checkIfACheckIsSet`);
  return response.data;
};

export const testSendEmailOnceToAllBeforeSoftwareExpiration = async ({ daysToExp1, daysToExp2, daysToExp3 }) => {
  const response = await api.post(`${EMAIL_URL}/testSendEmailOnceToAllBeforeSoftwareExpiration`, {
    days_to_exp1: daysToExp1,
    days_to_exp2: daysToExp2,
    days_to_exp3: daysToExp3
  });
  return response.data;
};

// ======================================================================================= //
// ================================= EMAIL ACTIVITIES =================================== //
export const fetchEmailActivity = async (payload) => {
  // GET EMAIL LIST FROM BACKEND
  const response = await api.post(`${EMAIL_URL}/getEmailActivity`, payload);

  return response.data;
};
