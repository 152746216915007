import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

export default {
  id: 'account',
  title: 'Account',
  type: 'group',
  children: [
    {
      id: 'Account',
      title: 'Account Settings',
      type: 'item',
      url: '/user/account',
      icon: ManageAccountsIcon,
      breadcrumbs: false
    }
  ]
};
