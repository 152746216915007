import api from 'utils/auth/api';

const ADMIN_URL = '/admin';

// ADMIN
// =================================================
export const fetchUserList = async () => {
  // GET USER LIST FROM BACKEND
  const response = await api.get(`${ADMIN_URL}/getUserList`);

  return response.data;
};

export const addUserList = async (userList) => {
  const response = await api.post(`${ADMIN_URL}/addUserList`, { userList });
  return response.data;
};

export const addUser = async (username) => {
  // CALL BACKEND TO ADD USER username
  const response = await api.post(`${ADMIN_URL}/addUser`, { username });
  return response.data;
};

export const removeUser = async (userId) => {
  // CALL BACKEND TO REMOVE USER userId
  const response = await api.post(`${ADMIN_URL}/removeUser`, { userId });
  return response.data;
};

export const fetchUserData = async (userId) => {
  // CALL GET USER PROFILE FROM BACKEND
  const response = await api.post(`${ADMIN_URL}/getUserProfile`, { userId });
  return response.data;
};

export const updateUser = async (userId, user) => {
  const response = await api.post(`${ADMIN_URL}/updateUser`, { userId, user });
  return response.data;
};

export const sendProfileEmail = async (userId) => {
  const response = await api.post(`${ADMIN_URL}/sendProfileEmail`, { userId });
  return response.data;
};

export const fetchSoftOpts = async () => {
  // CALL GET SOFTWARE LIST FROM BACKEND
  const response = await api.get(`/user/getSoftwareList`);
  return response.data;
};
