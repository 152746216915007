import { initialState } from './initialState';
import * as constants from './constants';
import produce from 'immer';

// ===========================|| ACCOUNT REDUCER ||=========================== //

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case constants.INIT_PROJECTS:
      return produce(state, (draft) => {
        const projects = action.payload;
        draft.projectIds = [];
        draft.projectsById = {};
        projects.forEach((project, idx) => {
          const { id } = project;
          draft.projectsById[id] = project;
          draft.projectIds.push(id);
          // Select the first project
          if (idx === 0) {
            draft.selectedProjectIds = [id];
          }
        });
      });

    case constants.SELECT_PROJECTS:
      return produce(state, (draft) => {
        const ids = action.payload;
        draft.selectedProjectIds = ids;
      });

    case constants.ADD_PROJECT:
      return produce(state, (draft) => {
        const project = action.payload;
        const { id } = project;
        draft.projectsById[id] = project;
        draft.projectIds.push(id);
        // Insert new case to select
        draft.selectedProjectIds.unshift(id);
      });

    case constants.REMOVE_PROJECT:
      return produce(state, (draft) => {
        const id = action.payload;
        // Delete in obj
        delete draft.projectsById[id];
        // Delete in order array
        const idx = draft.projectIds.findIndex((projectId) => projectId === id);
        if (idx !== -1) draft.projectIds.splice(idx, 1);
        // Delete in selectedProjectIds
        const selectId = draft.selectedProjectIds.findIndex((projectId) => projectId === id);
        if (selectId !== -1) draft.selectedProjectIds.splice(selectId, 1);
      });

    case constants.UPDATE_PROJECT:
      return produce(state, (draft) => {
        const project = action.payload;
        const { id } = project;
        draft.projectsById[id] = project;
      });

    case constants.UPDATE_SETTINGS:
      return produce(state, (draft) => {
        const settings = action.payload;
        draft.settings = settings;
      });

    default:
      return state;
  }
};

export default reducer;
