export const NAMESPACE = 'customized-software/producer-bhp';

export const EMPTY_ROW = {
  id: 'id',
  well: '',
  case: '',
  gas: 0,
  oil: 1000,
  water: 1000,
  p_wh: 200,
  temp_wh: 170,
  temp_bh: 190,
  tvd: 7000,
  di: 2,
  pb: 3000,
  api_o: 35,
  sg_g: 0.63,
  sg_w: 1,
  has_esp: false,
  esp_stages: 120,
  dp_esp_per_stage: 8
};

export const UNIT_ROW = {
  id: 'Unit',
  well: '-',
  case: '-',
  gas: 'Mscf/d',
  oil: 'bbl/d',
  water: 'bbl/d',
  p_wh: 'psia',
  temp_wh: 'F',
  temp_bh: 'F',
  tvd: 'ft',
  di: 'in',
  pb: 'psia',
  api_o: '-',
  sg_g: '-',
  sg_w: '-',
  has_esp: 'boolean',
  esp_stages: '-',
  dp_esp_per_stage: 'psi/stage',
  req_pwf: 'psia',
  pwf: 'psia'
};

export const INPUT_COLS = Object.keys(EMPTY_ROW).filter((d) => !d.startsWith('id'));

// CASE MANAGER
// ==========================================================================

export const INIT_PROJECTS = `${NAMESPACE}/INIT_PROJECTS`;
export const SELECT_PROJECTS = `${NAMESPACE}/SELECT_PROJECTS`;
export const ADD_PROJECT = `${NAMESPACE}/ADD_PROJECT`;
export const REMOVE_PROJECT = `${NAMESPACE}/REMOVE_PROJECT`;
export const UPDATE_PROJECT = `${NAMESPACE}/UPDATE_PROJECT`;
export const UPDATE_SETTINGS = `${NAMESPACE}/UPDATE_SETTINGS`;

export const SELECT_ROWS = `${NAMESPACE}/SELECT_ROWS`;
