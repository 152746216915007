export const PRESSURE_PROFILE_COLS = [
  {
    id: 'ang',
    name: 'Inclination Angle (deg)',
    decimal: 2
  },
  {
    id: 'bo',
    name: 'Oil FVF (rbbl/STB)',
    decimal: 3
  },
  {
    id: 'bw',
    name: 'Water FVF (rbbl/STB)',
    decimal: 3
  },
  {
    id: 'deng',
    name: 'Gas Density (lbm/ft3)',
    decimal: 3
  },
  {
    id: 'denl',
    name: 'Liquid Density (lbm/ft3)',
    decimal: 3
  },
  {
    id: 'deno',
    name: 'Oil Density (lbm/ft3)',
    decimal: 3
  },
  {
    id: 'denw',
    name: 'Water Density (lbm/ft3)',
    decimal: 3
  },
  {
    id: 'di',
    name: 'Pipe ID (in)',
    decimal: 3
  },
  {
    id: 'dl',
    name: 'Delta Length (ft)',
    decimal: 3
  },
  {
    id: 'dpdl',
    name: 'Total Pressure Gradient (psi/ft)',
    decimal: 3
  },
  {
    id: 'dpdl_f',
    name: 'Frictional Pressure Gradient (psi/ft)',
    decimal: 3
  },
  {
    id: 'dpdl_g',
    name: 'Gravitational Pressure Gradient (psi/ft)',
    decimal: 3
  },
  {
    id: 'ed',
    name: 'Relative Roughness (-)',
    decimal: 3
  },
  {
    id: 'free_glr',
    name: 'Free Gas Liquid Ratio (scf/bbl)',
    decimal: 3
  },
  {
    id: 'glr',
    name: 'Gas Liquid Ratio (scf/bbl)',
    decimal: 3
  },
  {
    id: 'hl',
    name: 'Liquid Holdup (-)',
    decimal: 3
  },
  {
    id: 'leng',
    name: 'Pipe Length (ft)',
    decimal: 3
  },
  {
    id: 'p',
    name: 'Pressure (psi)',
    decimal: 3
  },
  {
    id: 'pattern',
    name: 'Flow Pattern',
    decimal: null
  },
  {
    id: 'rso',
    name: 'Solution Gas Oil Ratio (scf/STBO)',
    decimal: 3
  },
  {
    id: 'rsw',
    name: 'Solution Gas Water Ratio (scf/STBO)',
    decimal: 3
  },
  {
    id: 'surl',
    name: 'Liquid Surface Tenstion (dyn/cm)',
    decimal: 3
  },
  {
    id: 'suro',
    name: 'Oil Surface Tenstion (dyn/cm)',
    decimal: 3
  },
  {
    id: 'surw',
    name: 'Water Surface Tenstion (dyn/cm)',
    decimal: 3
  },
  {
    id: 'temp',
    name: 'Temperature (F)',
    decimal: 3
  },
  {
    id: 'visg',
    name: 'Gas Viscosity (cp)',
    decimal: 3
  },
  {
    id: 'visl',
    name: 'Liquid Viscosity (cp)',
    decimal: 3
  },
  {
    id: 'viso',
    name: 'Oil Viscosity (cp)',
    decimal: 3
  },
  {
    id: 'visw',
    name: 'Water Viscosity (cp)',
    decimal: 3
  },
  {
    id: 'vsg',
    name: 'Superficial Gas Velocity (ft/s)',
    decimal: 3
  },
  {
    id: 'vsl',
    name: 'Superficial Liquid Velocity (ft/s)',
    decimal: 3
  },
  {
    id: 'zfac',
    name: 'Z Factor',
    decimal: 3
  }
];

export default PRESSURE_PROFILE_COLS;
