import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// reducer import
import snackbarReducer from './slices/snackbar';
import menuReducer from './slices/menu';
import globalReducer from './globalReducer';

// PAGE REDUCER IMPORT
// User Manager
import { injectableReducer as reducerUM } from 'pages/user-manager/duck';

// Well Completion
import { injectableReducer as reducerA } from 'pages/drilling-completion/WellInsight/duck';
import { injectableReducer as reducerB, constants } from 'pages/customized-software/WellTrajectory/duck';
import { injectableReducer as reducerC } from 'pages/drilling-completion/CompletionDesign/duck';
import { injectableReducer as reducerC1 } from 'pages/drilling-completion/DrillingDesign/duck';
import { injectableReducer as reducerD } from 'pages/customized-software/ProducerBHP/duck';
import { injectableReducer as reducerE } from 'pages/customized-software/AcidGasInjection/duck';
import { injectableReducer as reducerF } from 'pages/artificial-lift/RodPump/duck';
import { injectableReducer as reducerG } from 'pages/user-activity/duck';
import { injectableReducer as reducerH } from 'pages/email-activity/duck';
// ===========================|| COMBINE REDUCER ||=========================== //

const reducer = combineReducers({
  snackbar: snackbarReducer,
  global: globalReducer,
  menu: menuReducer,
  // Injectable Reducer from Pages
  ...reducerA,
  ...reducerB,
  ...reducerC,
  ...reducerC1,
  ...reducerD,
  ...reducerE,
  ...reducerF,
  ...reducerG,
  ...reducerH,
  ...reducerUM
});

const config = {
  key: 'app',
  storage,
  // Only perist the white list reducers
  whitelist: ['global', constants.NAMESPACE],
  keyPrefix: 'ppsc-'
};

export default persistReducer(config, reducer);
