import { createSelector } from 'reselect';
import { createCachedSelector } from 're-reselect';
import { initialState } from './initialState';
import { get, min, max, map, isEmpty } from 'lodash';
import * as d3 from 'd3';
import * as constants from './constants';

// CURRENT STORE SELECTORS
// ==========================

export const getStore = (store) => store[constants.NAMESPACE] || initialState;

export const projectsByIdSelector = (store) => getStore(store).projectsById;

export const selectedProjectIdsSelector = (store) => getStore(store).selectedProjectIds;

export const projectIdsSelector = (store) => getStore(store).projectIds;

export const settingsSelector = (store) => getStore(store).settings;

export const projectsSelector = createSelector(projectIdsSelector, projectsByIdSelector, (ids, byId) => ids.map((id) => byId[id]));

// SETTING SELECTORS
// ==========================

export const settingUnitSelector = createSelector(settingsSelector, (settings) => get(settings, 'unit', 'Field'));

// CURRENT CASE SELECTORS
// ==========================

export const currentProjectIdSelector = createSelector(selectedProjectIdsSelector, (selectedProjectIds) => {
  if (selectedProjectIds.length > 0) {
    return selectedProjectIds[0];
  }
  return null;
});

// Note: this will limit how many case data will be rendered
export const renderProjectIdsSelector = createSelector(selectedProjectIdsSelector, (selectedProjectIds) => selectedProjectIds.slice(0, 10));

export const currentProjectSelector = createSelector(projectsByIdSelector, currentProjectIdSelector, (byId, id) => byId[id] || undefined);

export const wellHeaderSelector = createSelector(currentProjectSelector, (obj) => get(obj, 'well_header', {}));

export const surveyDataSelector = createSelector(currentProjectSelector, (obj) => get(obj, 'directional_survey', []));

export const currentMethodSelector = createSelector(currentProjectSelector, (obj) => get(obj, 'directional_survey_method', undefined));

export const colorAttrSelector = createSelector(currentProjectSelector, (obj) => get(obj, 'color_attr', null));

export const colorStopsSelector = createSelector(currentProjectSelector, (obj) => get(obj, 'color_stops', null));

export const colorAttrMinSelector = createSelector(colorAttrSelector, surveyDataSelector, (colorAttr, rowData) => {
  const attrMin = min(rowData.map((d) => d[colorAttr]));
  return attrMin;
});

export const colorAttrMaxSelector = createSelector(colorAttrSelector, surveyDataSelector, (colorAttr, rowData) => {
  const attrMax = max(rowData.map((d) => d[colorAttr]));
  return attrMax;
});

// COMPARE CASES SELECTOR
// ==========================

export const dataByWellIdSelector = createCachedSelector(
  projectsByIdSelector,
  (state, projectId) => projectId,
  (projectsById, projectId) => {
    // Survey Data
    const rowData = get(projectsById, `${projectId}.directional_survey`, []);
    const colorAttr = get(projectsById, `${projectId}.color_attr`, 'z');
    const colorStops = get(projectsById, `${projectId}.color_stops`, null);
    const name = get(projectsById, `${projectId}.name`, null);

    // Chart Data
    const colorAttrData = rowData.map((d) => d[colorAttr]);
    const x = rowData.map((d) => d.x);
    const y = rowData.map((d) => d.y);
    const z = rowData.map((d) => d.z);
    const minColorAttr = min(colorAttrData);
    const maxColorAttr = max(colorAttrData);
    const colorData = [];

    if (!isEmpty(colorStops)) {
      const offsets = map(colorStops, 'offset');
      const colors = map(colorStops, 'color');
      const colorScale = d3.scale.linear().domain(offsets).range(colors).interpolate(d3.interpolateRgb);
      colorAttrData.forEach((value) => {
        let color = '#0000ff';
        if (maxColorAttr > minColorAttr && colorScale) {
          const offset = (value - minColorAttr) / (maxColorAttr - minColorAttr);
          color = colorScale(offset);
        }
        colorData.push(color);
      });
    }

    return {
      rowData,
      x,
      y,
      z,
      maxColorAttr,
      minColorAttr,
      name,
      colorData
    };
  }
)(
  (state, projectId) => projectId // Cache selectors by projectId
);
