// INITIAL STATE
// ==========================================================================

export const initialState = {
  selectedProjectIds: [],

  // Holding the project list
  projectIds: [],

  // holding the cache data
  projectsById: {},

  // Global settings for page
  settings: {
    unit: 'Field' // SI or Field
  }
};

export default initialState;
