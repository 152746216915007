export const NAMESPACE = 'customized-software/acid-gas-injection';

export const EMPTY_ROW = {
  id: 'id',
  well: null,
  case: null,
  fluid: 'co2',
  co2: 400,
  water: 400,
  p_wh: 2000,
  temp_wh: 100,
  temp_bh: 100,
  tvd: 6000,
  di: 3
};

export const UNIT_ROW = {
  id: 'Unit',
  well: '-',
  case: '-',
  fluid: '-',
  co2: 'Mscf/d',
  water: 'bbl/d',
  p_wh: 'psia',
  temp_wh: 'F',
  temp_bh: 'F',
  tvd: 'ft',
  di: 'in',
  pwf: 'psia'
};

export const INPUT_COLS = Object.keys(EMPTY_ROW).filter((d) => !d.startsWith('id'));

// CASE MANAGER
// ==========================================================================

export const INIT_PROJECTS = `${NAMESPACE}/INIT_PROJECTS`;
export const SELECT_PROJECTS = `${NAMESPACE}/SELECT_PROJECTS`;
export const ADD_PROJECT = `${NAMESPACE}/ADD_PROJECT`;
export const REMOVE_PROJECT = `${NAMESPACE}/REMOVE_PROJECT`;
export const UPDATE_PROJECT = `${NAMESPACE}/UPDATE_PROJECT`;
export const UPDATE_SETTINGS = `${NAMESPACE}/UPDATE_SETTINGS`;

export const SELECT_ROWS = `${NAMESPACE}/SELECT_ROWS`;
