// material-ui
import { Typography, Grid, TableContainer } from '@mui/material';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import { gridSpacing } from 'store/constant';
import useChartConfig from 'hooks/useChartConfig';

// Components
import FontFamily from '../../components/FontFamily';
import FontSize from '../../components/FontSize';
import FontStyle from '../../components/FontStyle';

const XAxisSetting = () => {
  const {
    onChangeXTicksFontFamily,
    xTicksFontFamily,
    onChangeXTicksFontSize,
    xTicksFontSize,
    onChangeXTicksFontStyle,
    xTicksFontStyle,
    onChangeXLabelFontFamily,
    xLabelFontFamily,
    onChangeXLabelFontSize,
    xLabelFontSize,
    onChangeXLabelFontStyle,
    xLabelFontStyle
  } = useChartConfig();

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item lg={2} xs={12}>
        <Typography fontSize={17} fontWeight={500} sx={{ pt: 2 }}>
          X Axis
        </Typography>
      </Grid>
      <Grid item lg={10} xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item lg={12} xs={12}>
            <SubCard>
              <Grid container spacing={gridSpacing}>
                <Grid item lg={12} xs={12} style={{ height: '50px' }}>
                  <Typography fontFamily={xLabelFontFamily} fontSize={xLabelFontSize} fontStyle={xLabelFontStyle}>
                    Label
                  </Typography>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TableContainer>
                    <FontFamily uniqueKey="xLabelFontFamily" fontFamily={xLabelFontFamily} onChangeFontFamily={onChangeXLabelFontFamily} />
                    <FontSize
                      uniqueKey="xLabelFontSize"
                      fontSize={xLabelFontSize}
                      onChangeFontSize={onChangeXLabelFontSize}
                      min={12}
                      max={22}
                    />
                    <FontStyle uniqueKey="xLabelFontStyle" fontStyle={xLabelFontStyle} onChangeFontStyle={onChangeXLabelFontStyle} />
                  </TableContainer>
                </Grid>
              </Grid>
            </SubCard>
          </Grid>
        </Grid>
        <Grid container spacing={gridSpacing}>
          <Grid item lg={12} xs={12}>
            <SubCard>
              <Grid container spacing={gridSpacing}>
                <Grid item lg={12} xs={12} style={{ height: '50px' }}>
                  <Typography fontFamily={xTicksFontFamily} fontSize={xTicksFontSize} fontStyle={xTicksFontStyle}>
                    Ticks
                  </Typography>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TableContainer>
                    <FontFamily uniqueKey="xTicksFontFamily" fontFamily={xTicksFontFamily} onChangeFontFamily={onChangeXTicksFontFamily} />
                    <FontSize
                      uniqueKey="xTicksFontSize"
                      fontSize={xTicksFontSize}
                      onChangeFontSize={onChangeXTicksFontSize}
                      min={10}
                      max={20}
                    />
                    <FontStyle uniqueKey="xTicksFontStyle" fontStyle={xTicksFontStyle} onChangeFontStyle={onChangeXTicksFontStyle} />
                  </TableContainer>
                </Grid>
              </Grid>
            </SubCard>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default XAxisSetting;
