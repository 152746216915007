import api from '../../api';

const BASE_URL = '/rod_pump/project';
const ADMIN_CATALOG_URL = '/rod_pump/admin/catalog';
const CATALOG_URL = '/rod_pump/catalog';
// const OPTIMIZATION_URL = 'rod_pump/optimization';

// PROJECT CRUD
// =================================================
export const fetchProjectList = async (owner) => {
  // GET PROJECT LIST FROM BACKEND
  const response = await api.get(`${BASE_URL}?owner=${owner}`);
  return response.data;
};

export const fetchDeletedProjectList = async () => {
  // GET DELETED PROJECT LIST FROM BACKEND
  const response = await api.get(`rod_pump/deleted_project?`);
  return response.data;
};

export const readProject = async (id) => {
  // GET USER LIST FROM BACKEND
  const response = await api.get(`${BASE_URL}/${id}`);
  return response.data;
};

export const updateProject = async (id, project) => {
  // UPDATE PROJECT IN BACKEND
  const response = await api.put(`${BASE_URL}/${id}`, project);
  return response.data;
};

export const addProject = async (project) => {
  // CALL BACKEND TO ADD PROJECT owner(user), name(project name)
  const response = await api.post(`${BASE_URL}`, project);
  return response.data;
};

export const duplicateProject = async (project) => {
  // CALL BACKEND TO ADD PROJECT owner(user), name(project name), id
  const response = await api.post(`${BASE_URL}`, project);
  return response.data;
};

export const removeProject = async (id) => {
  // CALL BACKEND TO REMOVE PROJECT id
  const response = await api.delete(`${BASE_URL}/${id}`);
  return response.data;
};

export const restoreProject = async (id) => {
  // CALL BACKEND TO REMOVE PROJECT id
  const response = await api.put(`${BASE_URL}/restore/${id}`);
  return response.data;
};

export const permanentDeleteProjects = async () => {
  // CALL BACKEND TO REMOVE PERMANENTLY PROJECTS THAT HAVE BEEN DELETED MORE THAN 30 DAYS
  const response = await api.delete(`${BASE_URL}`);
  return response.data;
};

// PROJECT CONTROLLER
// =================================================

export const calcOutputProject = async (id) => {
  // CALL BACKEND TO CALCULATE PROJECT id
  const response = await api.put(`${BASE_URL}/submit/${id}`);
  return response.data;
};

export const updateCalcOutputProject = async (id, project) => {
  // UPDATE PROJECT IN BACKEND AND CALCULATE PROJECT
  const response = await api.put(`${BASE_URL}/update_submit/${id}`, project);
  return response.data;
};

export const clearOutputProject = async (id) => {
  // CALL BACKEND TO CLEAR OUTPUT FOR PROJECT id
  const response = await api.delete(`${BASE_URL}/submit/${id}`);
  return response.data;
};

// ROD CATALOG TABLE DATA
// =================================================
export const fetchRodCatalogTableData = async () => {
  // GET ROD CATALOG TABLE DATA FROM BACKEND
  const response = await api.get('/rod_pump/rod_catalog_data');
  return response.data;
};

// ROD PUMP CATALOG
// =================================================
export const fetchCatalogList = async () => {
  // GET CATALOG LIST FROM BACKEND
  const response = await api.get(`${ADMIN_CATALOG_URL}?`);
  return response.data;
};

export const addCatalog = async (payload) => {
  // CALL BACKEND TO ADD CATALOG
  const response = await api.post(`${ADMIN_CATALOG_URL}`, payload);
  return response.data;
};

export const removeCatalog = async (catalogName) => {
  // CALL BACKEND TO REMOVE CATALOG catalogName
  const response = await api.delete(`${ADMIN_CATALOG_URL}/${catalogName}`);
  return response.data;
};

export const updateCatalog = async (catalogName, payload) => {
  // UPDATE CATALOG IN BACKEND
  const response = await api.patch(`${ADMIN_CATALOG_URL}/${catalogName}`, payload);
  return response.data;
};

// ROD PUMP CATALOG
// =================================================
export const fetchActiveCatalogList = async () => {
  // GET ACTIVE CATALOG LIST FROM BACKEND
  const response = await api.get(`${CATALOG_URL}/active?`);
  return response.data;
};

export const fetchCatalogdata = async (catalogName) => {
  // GET DATA OF SELECTED CATALOG FROM BACKEND
  const response = await api.get(`${CATALOG_URL}/${catalogName}`);
  return response.data;
};

// SENSITIVITY CRUD
// =================================================
export const fetchSensitivityList = async (projectId) => {
  // GET SENSITIVITY LIST FROM BACKEND
  const response = await api.get(`${BASE_URL}/${projectId}/sensitivity`);
  return response.data;
};

export const deleteAllSensitivities = async (projectId) => {
  // DELETE ALL SENSITIVITIES OF A PROJECT FROM BACKEND
  const response = await api.delete(`${BASE_URL}/${projectId}/sensitivity`);
  return response.data;
};

export const readSensitivity = async (sensitivityId) => {
  // READ SENSITIVITY FROM ID FROM BACKEND
  const response = await api.get(`${BASE_URL}/sensitivity/${sensitivityId}`);
  return response.data;
};

export const updateSensitivity = async (sensitivityId, sensitivity) => {
  // UPDATE BACKEND SENSITIVITY
  const response = await api.put(`${BASE_URL}/sensitivity/${sensitivityId}`, sensitivity);
  return response.data;
};

export const addSensitivity = async (sensitivity) => {
  // ADD SENSITIVITY TO BACKEND
  const response = await api.post(`${BASE_URL}/sensitivity`, sensitivity);
  return response.data;
};

export const duplicateSensitivity = async (sensitivity) => {
  // DUPLICATE SENSITIVITY IN BACKEND
  const response = await api.post(`${BASE_URL}/sensitivity`, sensitivity);
  return response.data;
};

export const removeSensitivity = async (sensitivityId) => {
  // REMOVE SENSITIVITY FROM BACKEND
  const response = await api.delete(`${BASE_URL}/sensitivity/${sensitivityId}`);
  return response.data;
};

export const runSensitivity = async (sensitivityId) => {
  // RUN SENSITIVITY
  const response = await api.post(`${BASE_URL}/sensitivity/${sensitivityId}/run`);
  return response.data;
};

export const readStatusSensitivity = async (sensitivityId) => {
  // READ PROGRESS STATUS FROM BACKEND
  const response = await api.get(`${BASE_URL}/sensitivity/${sensitivityId}/status`);
  return response.data;
};
