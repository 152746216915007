import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { get } from 'lodash';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';

// project imports
import { useConfirm } from 'ui-component/extended/ConfirmDialog';
import useConfig from 'hooks/useConfig';
import { useDispatch, useSelector } from 'store';
import { UPDATE_UNSAVED_STATUS } from 'store/types';
import { activeItem, openDrawer } from 'store/slices/menu';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// ===========================|| SIDEBAR MENU LIST ITEMS ||=========================== //

const NavItem = ({ item, level }) => {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down('xl'));

  const { borderRadius } = useConfig();
  const dispatch = useDispatch();
  const { openItem } = useSelector((state) => state.menu);
  const withUnsavedWork = useSelector((state) => get(state, 'global.withUnsavedWork', false));
  const unsavedWorkMsg = useSelector((state) => get(state, 'global.unsavedWorkMsg', ''));

  const Icon = item?.icon;
  const itemIcon = item?.icon ? (
    <Icon stroke={1.5} size="1.3rem" />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: openItem.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
        height: openItem.findIndex((id) => id === item?.id) > -1 ? 8 : 6
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  );

  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps;

  // disallow/allow navigation
  if (!withUnsavedWork) {
    listItemProps = { component: React.forwardRef((props, ref) => <Link {...props} to={item.url} ref={ref} />) };
  } else {
    listItemProps = {};
  }

  if (item.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  const itemHandler = (id) => {
    if (withUnsavedWork) {
      confirm({ title: 'Are you sure to navigate to different page ?', description: unsavedWorkMsg })
        .then(() => {
          dispatch({ type: UPDATE_UNSAVED_STATUS, withUnsavedWork: false });
          navigate(item.url, { replace: true });
          dispatch(activeItem([id]));
          if (matchesSM) dispatch(openDrawer(false));
        })
        .catch(() => {
          /* ... */
        });
    } else {
      navigate(item.url, { replace: true });
      dispatch(activeItem([id]));
      if (matchesSM) dispatch(openDrawer(false));
    }
  };

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split('/')
      .findIndex((id) => id === item.id);
    if (currentIndex > -1) {
      dispatch(activeItem([item.id]));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      sx={{
        borderRadius: `${borderRadius}px`,
        mb: 0.5,
        alignItems: 'flex-start',
        backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
        py: level > 1 ? 1 : 1.25,
        pl: `${level * 24}px`,
        pt: '0px',
        pb: '0px'
      }}
      selected={openItem?.findIndex((id) => id === item.id) > -1}
      onClick={() => itemHandler(item.id)}
    >
      <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>{itemIcon}</ListItemIcon>
      <ListItemText
        sx={{ pt: '4px' }}
        primary={
          <Typography variant={openItem?.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'} color="inherit">
            {item.title}
          </Typography>
        }
        secondary={
          item.caption && (
            <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
              {item.caption}
            </Typography>
          )
        }
      />
      {item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number
};

export default NavItem;
