// INITIAL STATE
// ==========================================================================

export const initialState = {
  selectedProjectIds: [],
  // Holding the well list
  projectIds: [],

  // holding the cache data
  projectsById: {},
  currentRowIds: []
};

export default initialState;
