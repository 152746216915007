import { v4 as UIDV4 } from 'uuid';
import produce from 'immer';
import { EMPTY_ROW } from 'utils/directional_survey/schema/survey_table_schema';

// WELL DESCRIPTION
// ==========================================================================

export const DEFAULT_DESCRIPTION = {
  api: '3305307390',
  uwi: '33053073900000',
  operator: 'OASIS PETROLEUM NORTH AMERICA LLC',
  well: '41-33 14BX',
  lease: '41-33 14BX',
  // Dropdown 'oil', 'gas', 'water_injector', 'gas_injector'
  well_type: 'oil', // { id: 'oil', label: 'Oil Producer'}
  // Dropdown 'H', 'V', 'D'
  hole_direction: 'H',

  // Geographical location
  country: 'USA',
  // Dropdown State
  state: 'ALABAMA',
  // Dropdown County
  county: 'AUTAUGA',

  basin: 'WILLISTON BASIN',
  field: 'SIVERSTON',
  formation: 'THREE FORKS UPPER',

  // Congressional Location
  township: null,
  ns_direction: 'N',
  range: null,
  ew_direction: 'E',
  section: 1,

  ns_footage: null,
  ns_start_line: 'FNL',
  ew_footage: null,
  ew_start_line: 'FWL',

  // Lat long location
  latitude: null,
  longitude: null,

  // Date
  spud_date: null,
  td_date: null,
  final_drilling_date: null,
  completed_date: null
};

// WELL GEOMETRY DATA
// ==========================================================================

export const getDefaultWellboreGeometry = () => {
  const row0Id = UIDV4();
  const row1Id = UIDV4();
  const row2Id = UIDV4();
  const row3Id = UIDV4();

  const row0 = produce(EMPTY_ROW, (draft) => {
    draft.id = row0Id;
  });
  const row1 = produce(EMPTY_ROW, (draft) => {
    draft.id = row1Id;
    draft.md = 500;
    draft.z = 500;
  });
  const row2 = produce(EMPTY_ROW, (draft) => {
    draft.id = row2Id;
    draft.md = 1000;
    draft.z = 1000;
  });

  const row3 = produce(EMPTY_ROW, (draft) => {
    draft.id = row3Id;
    draft.md = 8000;
    draft.z = 8000;
  });

  return {
    currentMethod: 'MC',
    colorAttr: 'x',
    colorStops: null,
    rowIds: [row0Id, row1Id, row2Id, row3Id],
    rowsById: {
      [row0Id]: row0,
      [row1Id]: row1,
      [row2Id]: row2,
      [row3Id]: row3
    }
  };
};

// DEFAULT CASE
// ==========================================================================

export const getDefaultCase = (name = 'New Case') => {
  const id = UIDV4();
  const createdDate = Date.now();
  return {
    id,
    name,
    createdDate,
    modifiedDate: createdDate,
    description: DEFAULT_DESCRIPTION,
    wellboreGeometry: getDefaultWellboreGeometry()
  };
};

// INITIAL STATE
// ==========================================================================

const DEFAULT_CASE = getDefaultCase();

export const initialState = {
  // Selection not saving to Back-end
  selectedCaseIds: [DEFAULT_CASE.id],

  // Data save in back-end
  caseIds: [DEFAULT_CASE.id],
  casesById: {
    [DEFAULT_CASE.id]: DEFAULT_CASE
  },
  // Global settings for page
  settings: {
    unit: 'Field' // SI or Field
  }
};

export default initialState;
