import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Typography, Grid, TableContainer } from '@mui/material';
import { IconChecks } from '@tabler/icons';

// project imports
import SubCard from 'ui-component/cards/SubCard';
import { gridSpacing } from 'store/constant';
import useChartConfig from 'hooks/useChartConfig';

// Components
import FontFamily from '../../components/FontFamily';
import FontSize from '../../components/FontSize';
import FontStyle from '../../components/FontStyle';

const YAxisSetting = () => {
  const {
    onChangeYTicksFontFamily,
    yTicksFontFamily,
    onChangeYTicksFontSize,
    yTicksFontSize,
    onChangeYTicksFontStyle,
    yTicksFontStyle,
    onChangeYLabelFontFamily,
    yLabelFontFamily,
    onChangeYLabelFontSize,
    yLabelFontSize,
    onChangeYLabelFontStyle,
    yLabelFontStyle,
    yLabelToGraph,
    onChangeYLabelToGraph
  } = useChartConfig();

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item lg={2} xs={12}>
        <Typography fontSize={17} fontWeight={500} sx={{ pt: 2 }}>
          Y Axis
        </Typography>
      </Grid>
      <Grid item lg={10} xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item lg={12} xs={12}>
            <SubCard>
              <Grid container spacing={gridSpacing}>
                <Grid item lg={12} xs={12} style={{ height: '50px' }}>
                  <Typography fontFamily={yLabelFontFamily} fontSize={yLabelFontSize} fontStyle={yLabelFontStyle}>
                    Label
                  </Typography>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TableContainer>
                    <FontFamily uniqueKey="yLabelFontFamily" fontFamily={yLabelFontFamily} onChangeFontFamily={onChangeYLabelFontFamily} />
                    <FontSize
                      uniqueKey="yLabelFontSize"
                      fontSize={yLabelFontSize}
                      onChangeFontSize={onChangeYLabelFontSize}
                      min={12}
                      max={22}
                    />
                    <FontStyle uniqueKey="yLabelFontStyle" fontStyle={yLabelFontStyle} onChangeFontStyle={onChangeYLabelFontStyle} />
                  </TableContainer>
                </Grid>
              </Grid>
            </SubCard>
          </Grid>
        </Grid>
        <Grid container spacing={gridSpacing}>
          <Grid item lg={12} xs={12}>
            <SubCard>
              <Grid container spacing={gridSpacing}>
                <Grid item lg={12} xs={12} style={{ height: '50px' }}>
                  <Typography fontFamily={yTicksFontFamily} fontSize={yTicksFontSize} fontStyle={yTicksFontStyle}>
                    Ticks
                  </Typography>
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TableContainer>
                    <FontFamily uniqueKey="yTicksFontFamily" fontFamily={yTicksFontFamily} onChangeFontFamily={onChangeYTicksFontFamily} />
                    <FontSize
                      uniqueKey="yTicksFontSize"
                      fontSize={yTicksFontSize}
                      onChangeFontSize={onChangeYTicksFontSize}
                      min={10}
                      max={20}
                    />
                    <FontStyle uniqueKey="yTicksFontStyle" fontStyle={yTicksFontStyle} onChangeFontStyle={onChangeYTicksFontStyle} />
                  </TableContainer>
                </Grid>
              </Grid>
            </SubCard>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default YAxisSetting;
